import * as yup from "yup";
import { REGEX } from "./constants";

const VALIDATOR_NAMES = {
  ["DECIMAL_NUMBER"]: "Decimal Number",
  ["POSITIVE_NUMBER"]: "Positive Number",
  ["DATE"]: "Date",
  ["EMAIL"]: "Email",
  ["SELECT"]: "Select",
  ["NULLABLE_STRING"]: "Nullable String",
  ["PHONE_NUMBER"]: "Phone Number",
  ["ONLY_ALPHABETS"]: "Only Alphabets",
};

const PATTERN_DECIMAL_NUMBER = /^\d+(\.\d{0,2})?$/;
const DECIMAL_VALIDATOR = yup
  .number()
  .positive()
  .test("is-decimal", "", (val) => {
    if (val != undefined) {
      return PATTERN_DECIMAL_NUMBER.test(val);
    }
    return true;
  })
  .required("");
const DECIMAL_VALIDATOR_NO_REQUIRED = yup
  .number()
  .positive()
  .notRequired()
  .min(0)
  .test("is-decimal", "", (val) => {
    if (val != undefined) {
      return PATTERN_DECIMAL_NUMBER.test(val);
    }
    return true;
  });

const PHONE_NUMBER = yup
  .string()
  .required("Phone number is required")
  .matches(REGEX.PHONE_NUMBER);

const EMAIL_VALIDATOR = yup
  .string()
  .email("Please enter a valid email")
  .required("Email is required")
  .matches(REGEX.EMAIL);

const STRING_VALIDATOR = yup
  .string()
  .matches(REGEX.STRING_VALIDATOR, "")
  .required();

const ALPHA_NUMERIC_VALIDATOR = yup
  .string()
  .matches(REGEX.ALPHANUMERIC, "")
  .required();

const POSITIVE_INTEGER = yup.number().positive().required();
const BOOLEAN_VALIDATOR = yup.bool().required().oneOf([true, false]);
const REQUIRED_DOC_VALIDATOR = yup.bool().required().oneOf([1, 2, 3]);
const DATE_VALIDATOR = yup.date().required();

const PASSWORD_VALIDATOR = yup
  .string()
  .max(16)
  .min(8)
  .required()
  .matches(REGEX.PASSWORD);

const CONFIRM_PASSWORD_VALIDATOR = yup
  .string()
  .oneOf([yup.ref("password"), null], "Password(s) must match")
  .required();

const GUID_VALIDATOR = yup.string().required().matches(REGEX.GUID);
const GUID_VALIDATOR_NO_REQUIRED = yup
  .string()
  .notRequired()
  .matches(REGEX.GUID);
const FILE_VALIDATOR = yup.mixed().nullable().notRequired();

const ONLY_ALPHABETS_VALIDATOR = yup
  .string()
  .matches(REGEX.ONLY_ALPHABETS, "")
  .required("ONLY ALPHABETS ALLOWED");

const SCHEMA_VALIDATOR = {
  ALPHA_NUMERIC_VALIDATOR,
  BOOLEAN_VALIDATOR: BOOLEAN_VALIDATOR,
  DATE_VALIDATOR: DATE_VALIDATOR,
  DECIMAL_VALIDATOR: DECIMAL_VALIDATOR,
  DECIMAL_VALIDATOR_NO_REQUIRED: DECIMAL_VALIDATOR_NO_REQUIRED,
  EMAIL_VALIDATOR: EMAIL_VALIDATOR,
  PHONE_NUMBER_VALIDATOR: PHONE_NUMBER,
  POSITIVE_INTEGER: POSITIVE_INTEGER,
  PASSWORD_VALIDATOR: PASSWORD_VALIDATOR,
  CONFIRM_PASSWORD_VALIDATOR: CONFIRM_PASSWORD_VALIDATOR,
  STRING_VALIDATOR: STRING_VALIDATOR,
  REQUIRED_DOC_VALIDATOR: REQUIRED_DOC_VALIDATOR,
  GUID_VALIDATOR: GUID_VALIDATOR,
  GUID_VALIDATOR_NO_REQUIRED: GUID_VALIDATOR_NO_REQUIRED,
  FILE_VALIDATOR,
  ONLY_ALPHABETS_VALIDATOR: ONLY_ALPHABETS_VALIDATOR,
};

export default SCHEMA_VALIDATOR;
