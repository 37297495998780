import SCHEMA_VALIDATOR from "../../core/utils/schema-validator";
import * as yup from "yup";
const {
  ALPHA_NUMERIC_VALIDATOR,
  DATE_VALIDATOR,
  DECIMAL_VALIDATOR_NO_REQUIRED,
  FILE_VALIDATOR,
  GUID_VALIDATOR,
  PHONE_NUMBER_VALIDATOR,
  STRING_VALIDATOR,
  POSITIVE_INTEGER,
} = SCHEMA_VALIDATOR;

const IMMIGRATION_VALIDATION_SCHEMA = yup.object({
  dob: DATE_VALIDATOR,
  qualStreamId: GUID_VALIDATOR,
  ieltsScore: DECIMAL_VALIDATOR_NO_REQUIRED,
  listeningScore: DECIMAL_VALIDATOR_NO_REQUIRED,
  readingScore: DECIMAL_VALIDATOR_NO_REQUIRED,
  writingScore: DECIMAL_VALIDATOR_NO_REQUIRED,
  speakingScore: DECIMAL_VALIDATOR_NO_REQUIRED,
  address: ALPHA_NUMERIC_VALIDATOR,
  phoneNumber: PHONE_NUMBER_VALIDATOR,
  father: STRING_VALIDATOR,
  applicantName: STRING_VALIDATOR,
  boardId: GUID_VALIDATOR,
  visaCountryId: GUID_VALIDATOR,
  districtId: GUID_VALIDATOR,
  stateId: GUID_VALIDATOR,
  visaTypeId: GUID_VALIDATOR,
  alternateNo: PHONE_NUMBER_VALIDATOR,
  passportNo: ALPHA_NUMERIC_VALIDATOR,
  file: FILE_VALIDATOR,
  tenthPercentage: POSITIVE_INTEGER,
  twelfthPercentage: POSITIVE_INTEGER,
  fileModeId: POSITIVE_INTEGER,
  //gradeId: GUID_VALIDATOR,
  grade: ALPHA_NUMERIC_VALIDATOR,
});

export default IMMIGRATION_VALIDATION_SCHEMA;
