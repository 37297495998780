import {
  AiOutlineBlock,
  AiOutlineEye,
  AiOutlineFileProtect,
  AiOutlineFileSync,
} from "react-icons/ai";
import { colors } from "../../theme/styles/colors";
import { BsBookmarkPlus } from "react-icons/bs";
import { ROUTE_LINKS } from "./route-links";
import { MENU_NAMES } from "./constants";

export const GRID_OTHER_ACTIONS = {
  BRANCH: [
    {
      Name: "View Permission",
      Active: true,
      Visibility: true,
      Icon: <AiOutlineEye color={"#F0AC00"} />,
      Key: "viewPermission",
      NavigateRoute: ROUTE_LINKS[MENU_NAMES.VIEW_PERMISSIONS],
    },
  ],
  IMMIGRATION: [
    {
      Name: "Add Special Remark",
      Active: true,
      Visibility: true,
      Icon: <BsBookmarkPlus color={colors.badge.violet} />,
      Key: "specialRemarks",
      NavigateRoute: "",
    },
    {
      Name: "Update Status",
      Active: true,
      Visibility: true,
      Icon: <AiOutlineBlock color={colors.badge.yellow} />,
      Key: "updateStatus",
      NavigateRoute: "",
    },
    {
      Name: "Manage Payment",
      Active: true,
      Visibility: true,
      Icon: <AiOutlineFileProtect color={colors.purple.purple900} />,
      Key: "viewPayment",
      NavigateRoute: ROUTE_LINKS[MENU_NAMES.VIEW_PAYMENT],
    },
    {
      Name: "Manage Document",
      Active: true,
      Visibility: true,
      Icon: <AiOutlineFileProtect color={colors.purple.purple900} />,
      Key: "manageDocument",
      NavigateRoute: "",
    },
  ],
  ENQUIRY: [
    {
      Name: "Add Special Remark",
      Active: true,
      Visibility: true,
      Icon: <BsBookmarkPlus color={colors.badge.violet} />,
      Key: "specialRemarks",
      NavigateRoute: "",
    },
    {
      Name: "Convert to file",
      Active: true,
      Visibility: true,
      Icon: <AiOutlineFileSync />,
      Key: "convertToFile",
      NavigateRoute: ROUTE_LINKS[MENU_NAMES.ADD_IMMIGRATION],
    },
    {
      Name: "Update Status",
      Active: true,
      Visibility: true,
      Icon: <AiOutlineBlock color={colors.badge.yellow} />,
      Key: "updateStatus",
      NavigateRoute: "",
    },
    {
      Name: "Update Followup",
      Active: true,
      Visibility: true,
      Icon: <BsBookmarkPlus color={colors.badge.yellow} />,
      Key: "updateFollowup",
      NavigateRoute: "",
    },
  ],
};
