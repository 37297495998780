import React, { useEffect } from "react";
import {
  Box,
  Container,
  Heading,
  Tbody,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  TableContainer,
  Td,
  Th,
  Tr,
  ModalFooter,
  Table,
  Thead,
  useDisclosure,
  SimpleGrid,
  AccordionIcon,
  AccordionItem,
  Accordion,
  AccordionButton,
  AccordionPanel,
  CircularProgress,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { colors } from "../../theme/styles/colors";
import BreadcrumbBox from "../../theme/components/breadcrumb";
import { Form, Formik } from "formik";
import * as yup from "yup";
import TextInput from "../../theme/components/input/text-input";
import { GET_BREADCRUMB_ROUTES } from "../../core/utils/navbar-items";
import { MENU_NAMES, PAGE_SIZE } from "../../core/utils/constants";
import { ROUTE_LINKS } from "../../core/utils/route-links";
import SelectInput from "../../theme/components/input/select-input";
import { GRID_COLUMNS } from "../../core/utils/grid-columns";
import Pagination from "../../theme/components/pagination/pagination";
import { getAll, getAllowedRoles, handleResetState } from "../../core/utils/common-function";
import api from "../../core/api";
import SCHEMA_VALIDATOR from "../../core/utils/schema-validator";
import SwitchInput from "../../theme/components/input/switch-input";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import DeleteModal from "../../theme/components/modals/delete-modal";
import { SuccessAlert } from "../../core/utils/common-sweet-alert";
import { useDispatch, useSelector } from "react-redux";
import { rebindBranches } from "../../core/store/actions";

const AddBranch = () => {
  const selector = useSelector((_) => _);
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(3);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [state, setState] = useState();
  const [district, setDistrict] = useState();
  const [role, setRole] = useState();
  const [user, setUser] = useState();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const onChange = (page) => {};
  const [show, setShow] = useState(false);
  const handleToggle = () => {
    setShow(!show);
  };
  const {
    GUID_VALIDATOR,
    BOOLEAN_VALIDATOR,
    POSITIVE_INTEGER,
    STRING_VALIDATOR,
    PASSWORD_VALIDATOR,
  } = SCHEMA_VALIDATOR;
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableRaddowData, setTableRowData] = useState([]);
  const [array, setArr] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [apiPayload, setAPIPayload] = useState({
    filter: [],
    pageSize: PAGE_SIZE,
    pageIndex: 1,
  });
  const [permissionUsers, setPermissionUsers] = useState([]);
  const [permissionIndex, setPermissionIndex] = useState(null);
  const [payload, setPayload] = useState({
    filter: [],
    pageSize: 0,
    pageIndex: 0,
  });
  const [initialState, setInitialState] = useState({
    districtId: null,
    stateId: null,
    name: "",
    isActive: null,
  });
  const [permissionState, setPermissionState] = useState({
    roleId: null,
    userId: null,
    userName: "",
    password: "",
  });

  const validationSchema = yup.object({
    name: STRING_VALIDATOR,
    stateId: GUID_VALIDATOR,
    districtId: GUID_VALIDATOR,
    isActive: BOOLEAN_VALIDATOR,
  });

  const permissionSchema = yup.object({
    userId: GUID_VALIDATOR,
    roleId: POSITIVE_INTEGER,
    userName: STRING_VALIDATOR,
    password: PASSWORD_VALIDATOR,
  });

  const handlePermissionDelete = (index) => {
    permissionUsers.splice(index, 1);
    setPermissionUsers([...permissionUsers]);
  };

  const resetPermissionPopup = () => {
    setIsView(false);
    setIsEdit(false);
    setPermissionIndex(null);
    handleResetState(permissionState, setPermissionState);
    onClose();
  };

  const getAllPromises = () => {
    const district = getAll("District", payload);
    const state = getAll("State", payload);
    const role = getAllowedRoles(payload);
    const user = getAll("User", {
      filter: [],
      pageSize: 0,
      pageIndex: 0,
      branchId: selector.branch.userBranchId,
    });
    Promise.all([district, state, role, user])
      .then((res) => {
        const [dist, stat, role, user] = res;
        const district = dist.data.data.map((dist) => ({
          text: dist.name,
          value: dist.districtId,
        }));
        const states = stat.data.data.map((sta) => ({
          text: sta.name,
          value: sta.stateId,
        }));
        const roles = role.data.data.map((role) => ({
          text: role.name,
          value: role.roleId,
        }));
        const users = user.data.data.map((user) => ({
          text: user.name,
          value: user.userId,
        }));

        setDistrict(district);
        setState(states);
        setRole(roles);
        setUser(users);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    document.title = "Add Branch | Digital Filing";
    getAllPromises();
  }, []);

  return (
    <Box>
      <Container maxW="container.xll" padding={"20px"} paddingBottom={"0px"}>
        <Box>
          <BreadcrumbBox route={GET_BREADCRUMB_ROUTES(MENU_NAMES.ADD_BRANCH)} />
        </Box>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialState}
          onSubmit={(values) => {
            let finalPayload = {
              name: values.name,
              stateId: values.stateId,
              districtId: values.districtId,
              isActive: values.isActive,
            };
            api
              .post(`/Branch/create`, { ...finalPayload })
              .then((res) => {
                dispatch(rebindBranches(true));
                SuccessAlert("Record created successfully");
                navigate(ROUTE_LINKS[MENU_NAMES.BRANCH]);
              })
              .catch((err) => {
                console.log(err, "err");
              });
          }}
        >
          {({ handleSubmit, handleChange, values, errors }) => (
            <Form
              noValidate={true}
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <Box
                color={colors.white}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box>
                  <Heading variant={"h1"}>Create New Branch</Heading>
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  gap={"10px"}
                  marginBottom={"20px"}
                >
                  <Button
                    variant={"warning"}
                    onClick={() => {
                      navigate("/branch");
                    }}
                  >
                    Discard
                  </Button>
                  <Button variant={"primary"} type="submit">
                    {isLoading ? (
                      <CircularProgress
                        isIndeterminate
                        size="24px"
                        color={colors.white}
                      />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Box>
              </Box>
              <Box
                border={"1px solid #e6e6e6"}
                padding={"20px"}
                borderRadius={"10px"}
              >
                <SimpleGrid
                  columns={{ base: "1", sm: "1", md: "4", lg: "4" }}
                  spacingX="40px"
                  padding={"0px 0 20px "}
                  paddingBottom={"0px"}
                >
                  <TextInput
                    type="text"
                    isRequired={true}
                    name="name"
                    value={values.name}
                    label={{
                      text: "Name",
                      colors: errors.name ? "red !important" : "",
                      variant: errors.name ? "errored" : "",
                    }}
                    onChange={(e) => handleChange(e)}
                    placeholder={""}
                    variant={errors.name ? "errored" : "flushed"}
                    isReadOnly={isView}
                  />
                  <SelectInput
                    name="stateId"
                    formControl={{ id: "stateId", variant: "floating" }}
                    isRequired={true}
                    isReadOnly={isView}
                    value={values.stateId}
                    label={{
                      text: "State",
                      colors: errors.stateId ? "red !important" : "",
                      variant: errors.stateId ? "errored" : "",
                    }}
                    options={state}
                    variant={errors.stateId ? "errored" : "flushed"}
                    onChange={(e) => handleChange(e)}
                  />
                  <SelectInput
                    name="districtId"
                    formControl={{ id: "districtId", variant: "floating" }}
                    isRequired={true}
                    isReadOnly={isView}
                    value={values.districtId}
                    label={{
                      text: "District",
                      colors: errors.districtId ? "red !important" : "",
                      variant: errors.districtId ? "errored" : "",
                    }}
                    options={district}
                    variant={errors.districtId ? "errored" : "flushed"}
                    onChange={(e) => handleChange(e)}
                  />
                  <SwitchInput
                    placeholder={""}
                    isRequired={true}
                    isReadOnly={isView}
                    value={values.isActive}
                    name="isActive"
                    variant={errors.isActive ? "errored" : "flushed"}
                    label={{
                      text: "Active",
                      colors: errors.isActive ? "red !important" : "",
                      variant: errors.isActive ? "errored" : "",
                      fontSize: "14px",
                    }}
                    formControl={{ id: "isActive" }}
                    onChange={(e) => handleChange(e)}
                  />
                </SimpleGrid>
                <Box>
                  <Accordion defaultIndex={[0]} allowMultiple>
                    <AccordionItem
                      borderRadius={"10px"}
                      border={"1px solid #e6e6e6"}
                    >
                      <AccordionButton backgroundColor={"#fffaf0"}>
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontWeight={"600"}
                        >
                          Branch User Permission
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel pb={4}>
                        <Box display={"flex"} justifyContent={"end"}>
                          <Text
                            color={colors.primaryColor}
                            outlineColor={"ActiveBorder"}
                            onClick={onOpen}
                            textDecoration={"underline"}
                            fontSize={"13px"}
                            cursor={"pointer"}
                          >
                            Add User Permission
                          </Text>
                        </Box>
                        <TableContainer fontSize={"15px"} borderRadius={"10px"}>
                          <Table variant="striped">
                            <Thead>
                              <Tr>
                                <Th paddingLeft={"20px"} paddingRight={"5px"}>
                                  Action
                                </Th>
                                {GRID_COLUMNS.BRANCH_USER_PERMISSIONS.map(
                                  (column, index) => (
                                    <Th>{column.Name}</Th>
                                  )
                                )}
                              </Tr>
                            </Thead>
                            <Tbody>
                              {permissionUsers.length > 0 ? (
                                permissionUsers.map((item, index) => {
                                  return (
                                    <Tr key={index}>
                                      <Td>
                                        <Menu isLazy>
                                          <MenuButton>
                                            <BsThreeDotsVertical />
                                          </MenuButton>
                                          <MenuList minWidth={"107px"}>
                                            <MenuItem
                                              display={"flex"}
                                              gap={"10px"}
                                              onClick={() => {
                                                setIsView(false);
                                                setIsEdit(true);
                                                setPermissionIndex(index);
                                                setPermissionState(item);
                                                onOpen();
                                              }}
                                            >
                                              <FiEdit />
                                              Edit
                                            </MenuItem>
                                            <MenuItem
                                              display={"flex"}
                                              color={"#E61515"}
                                              gap={"10px"}
                                              onClick={() =>
                                                handlePermissionDelete(index)
                                              }
                                            >
                                              <AiFillDelete />
                                              Delete
                                            </MenuItem>
                                          </MenuList>
                                        </Menu>
                                      </Td>
                                      <Td>{item.roleName}</Td>
                                      <Td>{item.user}</Td>
                                      <Td>{item.userName}</Td>
                                      <Td>{item.password}</Td>
                                    </Tr>
                                  );
                                })
                              ) : (
                                <Tr>
                                  <Td
                                    rowSpan={"10"}
                                    colSpan={"28"}
                                    textAlign={"center"}
                                  >
                                    NO RECORDS FOUND
                                  </Td>
                                </Tr>
                              )}
                            </Tbody>
                          </Table>
                        </TableContainer>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
        <Modal isOpen={isOpen} onClose={onClose} minWidth={"20%"} isCentered>
          <ModalOverlay />
          <Formik
            validationSchema={permissionSchema}
            initialValues={permissionState}
            onSubmit={(values) => {
              let payload = {
                roleId: values.roleId,
                userName: values.userName,
                userId: values.userId,
                password: values.password,
                user: user.filter((x) => x.value == values.userId)[0].text,
                roleName: role.filter((x) => x.value == values.roleId)[0].text,
              };

              if (isEdit) {
                permissionUsers[permissionIndex] = payload;
                setPermissionUsers([...permissionUsers]);
                setIsEdit(false);
                setPermissionIndex(null);
                handleResetState(permissionState, setPermissionState);
              } else
                setPermissionUsers((oldState) => [...oldState, { ...payload }]);

              onClose();
            }}
          >
            {({ handleSubmit, handleChange, values, errors }) => (
              <Form
                noValidate={true}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <ModalContent>
                  <ModalHeader>
                    {isEdit
                      ? "Edit Permission"
                      : isView
                      ? "View Permission "
                      : "Add Permission "}
                  </ModalHeader>
                  <ModalCloseButton
                    bg={colors.primaryColor}
                    color={colors.white}
                    position={"absolute"}
                    top={"15px"}
                  />
                  <ModalBody padding={"20px"}>
                    <SimpleGrid
                      columns={{ base: "1", sm: "1", md: "1", lg: "1" }}
                      spacingX="-3px"
                      spacingY="-6px"
                      gap={"10px"}
                    >
                      <SelectInput
                        name="roleId"
                        formControl={{ id: "roleId", variant: "floating" }}
                        isRequired={true}
                        isReadOnly={isLoading}
                        value={values.roleId}
                        label={{
                          text: "Role",
                          colors: errors.roleId ? "red !important" : "",
                          variant: errors.roleId ? "errored" : "",
                        }}
                        options={role}
                        variant={errors.roleId ? "errored" : "flushed"}
                        onChange={(e) => handleChange(e)}
                      />
                      <SelectInput
                        name="userId"
                        formControl={{ id: "userId", variant: "floating" }}
                        isRequired={true}
                        isReadOnly={isLoading}
                        value={values.userId}
                        label={{
                          text: "User",
                          colors: errors.userId ? "red !important" : "",
                          variant: errors.userId ? "errored" : "",
                        }}
                        options={user}
                        variant={errors.userId ? "errored" : "flushed"}
                        onChange={(e) => handleChange(e)}
                      />
                      <TextInput
                        type="text"
                        isRequired={true}
                        name="userName"
                        value={values.userName}
                        label={{
                          text: "Username",
                          colors: errors.userName ? "red !important" : "",
                          variant: errors.userName ? "errored" : "",
                        }}
                        onChange={(e) => handleChange(e)}
                        placeholder={""}
                        variant={errors.userName ? "errored" : "flushed"}
                        isReadOnly={isLoading}
                      />
                      <TextInput
                        type="password"
                        isRequired={true}
                        name="password"
                        value={values.password}
                        label={{
                          text: "Password",
                          colors: errors.password ? "red !important" : "",
                          variant: errors.password ? "errored" : "",
                        }}
                        onChange={(e) => handleChange(e)}
                        placeholder={""}
                        variant={errors.password ? "errored" : "flushed"}
                        isReadOnly={isLoading}
                      />
                    </SimpleGrid>
                  </ModalBody>
                  <ModalFooter
                    justifyContent={"space-between"}
                    borderTop={"1px solid #EDEDED "}
                  >
                    <Button
                      variant={"secondaryOutline"}
                      onClick={() => resetPermissionPopup()}
                    >
                      {isView ? "Back" : "Discard"}
                    </Button>
                    <Button variant={"primary"} type="submit">
                      {isLoading ? (
                        <CircularProgress
                          isIndeterminate
                          size="24px"
                          color={colors.white}
                        />
                      ) : isEdit ? (
                        "Save"
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Form>
            )}
          </Formik>
        </Modal>
      </Container>
    </Box>
  );
};
export default AddBranch;
