import React, { useEffect } from "react";
import { useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Container,
  Heading,
  SimpleGrid,
  CircularProgress,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import BreadcrumbBox from "../../theme/components/breadcrumb";
import TextInput from "../../theme/components/input/text-input";
import { MENU_NAMES, ROLES } from "../../core/utils/constants";
import { GET_BREADCRUMB_ROUTES } from "../../core/utils/navbar-items";
import { colors } from "../../theme/styles/colors";
import { ROUTE_LINKS } from "../../core/utils/route-links";
import { Form, Formik } from "formik";
import * as yup from "yup";
import TextArea from "../../theme/components/input/text-area";
import SelectInput from "../../theme/components/input/select-input";
import {
  CREATE_RESPONSE,
  convertDateFormat,
  getAll,
  getById,
  getUsersByRole,
} from "../../core/utils/common-function";
import { useDispatch, useSelector } from "react-redux";
import SCHEMA_VALIDATOR from "../../core/utils/schema-validator";
import api from "../../core/api";
import SwitchInput from "../../theme/components/input/switch-input";
import { color } from "framer-motion";
import { SuccessAlert } from "../../core/utils/common-sweet-alert";
import { GRID_COLUMNS } from "../../core/utils/grid-columns";
import Grid from "../../theme/components/grid/grid";

const AddEnquiry = (props) => {
  const navigate = useNavigate();
  const selector = useSelector((_) => _);
  const dispatch = useDispatch();
  const params = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [branchId, setBranchId] = useState(selector.branch.userBranchId);
  const [counsellorState, setCounsellorState] = useState([]);
  const [branch, setBranch] = useState([]);
  const [qualification, setQualification] = useState([]);
  const [board, setBoard] = useState([]);
  const [visaCountry, setVisaCountry] = useState([]);
  const [visaType, setVisaType] = useState([]);
  const [enquiryStatus, setEnquiryStatus] = useState([]);
  const [reference, setReference] = useState([]);
  const [telecallerState, setTelecallerState] = useState([]);
  const [enquiryRemarks, setEnquiryRemarks] = useState([]);
  const [enquiryId, setEnquiryId] = useState(params.state.id);
  const isEdit = params.state.isEdit;
  const isView = params.state.isView;
  const [state, setState] = useState({
    cityDescription: "",
  });
  const [initialState, setInitialState] = useState({
    emailId: "",
    applicantName: "",
    guardianName: "",
    address: "",
    phoneNumber: "",
    dob: "",
    lastPercentage: 0,
    passoutYear: 0,
    ieltsScore: 0,
    listeningScore: 0,
    readingScore: 0,
    writingScore: 0,
    speakingScore: 0,
    remark: "",
    enquiryStatusId: 0,
    boardId: "",
    qualStreamId: "",
  });
  const {
    ALPHA_NUMERIC_VALIDATOR,
    BOOLEAN_VALIDATOR,
    DATE_VALIDATOR,
    DECIMAL_VALIDATOR,
    EMAIL_VALIDATOR,
    POSITIVE_INTEGER,
    PASSWORD_VALIDATOR,
    PHONE_NUMBER_VALIDATOR,
    GUID_VALIDATOR,
    STRING_VALIDATOR,
    GUID_VALIDATOR_NO_REQUIRED
  } = SCHEMA_VALIDATOR;

  const validationSchema = yup.object({
    dob: DATE_VALIDATOR,
    followUpDate: DATE_VALIDATOR,
    // ieltsScore: DECIMAL_VALIDATOR,
    // listeningScore: DECIMAL_VALIDATOR,
    // readingScore: DECIMAL_VALIDATOR,
    // writingScore: DECIMAL_VALIDATOR,
    // speakingScore: DECIMAL_VALIDATOR,
    address: ALPHA_NUMERIC_VALIDATOR,
    remark: ALPHA_NUMERIC_VALIDATOR,
    phoneNumber: PHONE_NUMBER_VALIDATOR,
    name: STRING_VALIDATOR,
    enquiryStatusId: GUID_VALIDATOR,
    qualStreamId: GUID_VALIDATOR_NO_REQUIRED,
    boardId: GUID_VALIDATOR_NO_REQUIRED,
    visaTypeId: GUID_VALIDATOR,
    visaCountryId: GUID_VALIDATOR,
    counsellorId: GUID_VALIDATOR,
    referenceId: GUID_VALIDATOR,
  });

  const getSingleRecord = async () => {
    try {
      if (enquiryId) {
        const record = (await getById("Enquiry", enquiryId)).data;
        if (record && Object.keys(record).length > 0) {
          if (record.enquiryRemark) {
            const remarks = CREATE_RESPONSE(
              GRID_COLUMNS.ENQUIRY_REMARK_LOGS,
              record.enquiryRemark
            );
            setEnquiryRemarks(remarks);
          }
          record.remark = record.remarks;
          setInitialState(record);
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getSingleRecord();
  }, []);

  useEffect(() => {
    document.title = `${
      isView ? "View" : isEdit ? "Edit" : "Add"
    } Enquiry | Digital Filing`;
    if (selector.branch.userBranchId) getAllPromises();
  }, [selector.branch.userBranchId]);

  const [payload, setPayload] = useState({
    filter: [],
    pageSize: 0,
    pageIndex: 0,
  });
  const getAllPromises = () => {
    setBranchId(selector.branch.userBranchId);
    const getBranchRequest = getAll("Branch", payload);
    const VisaCountry = getAll("VisaCountry", payload);
    const Reference = getAll("Reference", payload);
    const VisaType = getAll("VisaType", payload);
    const Board = getAll("Board", payload);
    const Qualification = getAll("QualStream", payload);
    const EnquiryStatus = getAll("EnquiryStatus", payload);
    const getCounsellor = getUsersByRole(
      ROLES.COUNSELLOR,
      selector.branch.userBranchId
    );
    const getTelecaller = getUsersByRole(
      ROLES.TELE_CALLER,
      selector.branch.userBranchId
    );

    Promise.all([
      getBranchRequest,
      VisaCountry,
      EnquiryStatus,
      VisaType,
      Reference,
      Board,
      getCounsellor,
      getTelecaller,
      Qualification,
    ])
      .then((res) => {
        const [
          branchResponse,
          visaCountry1,
          enquiryStatus1,
          visaType1,
          reference1,
          board1,
          counsellor,
          telecaller,
          qualification1,
        ] = res;
        const Branch = branchResponse.data.data.map((branch) => ({
          text: branch.name,
          value: branch.branchId,
        }));
        const VisaCountry = visaCountry1.data.data.map((visaCountry1) => ({
          text: visaCountry1.name,
          value: visaCountry1.visaCountryId,
        }));
        const EnquiryStatus = enquiryStatus1.data.data.map(
          (enquiryStatus1) => ({
            text: enquiryStatus1.name,
            value: enquiryStatus1.enquiryStatusId,
          })
        );
        const Reference = reference1.data.data.map((reference1) => ({
          text: reference1.name,
          value: reference1.referenceId,
        }));
        const VisaType = visaType1.data.data.map((visaType1) => ({
          text: visaType1.name,
          value: visaType1.visaTypeId,
        }));
        const Board = board1.data.data.map((board1) => ({
          text: board1.name,
          value: board1.boardId,
        }));
        const counsellorResp = counsellor.data.map((counsler) => ({
          text: counsler.name,
          value: counsler.userId,
        }));
        const telecallerrResp = telecaller.data.map((telecaler) => ({
          text: telecaler.name,
          value: telecaler.userId,
        }));
        const Qualification = qualification1.data.data.map(
          (qualification1) => ({
            text: qualification1.name,
            value: qualification1.qualStreamId,
          })
        );
        setBranch(Branch);
        setVisaCountry(VisaCountry);
        setEnquiryStatus(EnquiryStatus);
        setVisaType(VisaType);
        setReference(Reference);
        setBoard(Board);
        setCounsellorState(counsellorResp);
        setTelecallerState(telecallerrResp);
        setQualification(Qualification);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  return (
    <Container maxW="container.xll" padding={"20px "}>
      <Box>
        <BreadcrumbBox
          route={
            isEdit
              ? GET_BREADCRUMB_ROUTES(MENU_NAMES.EDIT_ENQUIRY)
              : GET_BREADCRUMB_ROUTES(MENU_NAMES.VIEW_ENQUIRY)
          }
        />
      </Box>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialState}
        enableReinitialize={true}
        onSubmit={(values) => {
          let finalPayload = {
            enquiryId: values.enquiryId,
            enquiryNumber: values.enquiryNumber,
            branchId: values.branchId,
            counsellorId: values.counsellorId,
            telecallerId: values.telecallerId,
            enquiryStatusId: values.enquiryStatusId,
            enquiryDate: values.enquiryDate,
            followUpDate: values.followUpDate,
            name: values.name,
            phoneNumber: values.phoneNumber,
            guardianName: values.guardianName,
            address: values.address,
            dob: values.dob,
            emailId: values.emailId,
            ieltsScore: values.ieltsScore,
            listeningScore: values.listeningScore,
            readingScore: values.readingScore,
            writingScore: values.writingScore,
            speakingScore: values.speakingScore,
            qualStreamId: values.qualStreamId,
            boardId: values.boardId,
            refusal: values.refusal,
            refusalRemark: values.refusalRemark,
            travelHistory: values.travelHistory,
            travelRemark: values.travelRemark,
            visaCountryId: values.visaCountryId,
            visaTypeId: values.visaTypeId,
            referenceId: values.referenceId,
            gradeId: values.gradeId,
            remarks: values.remark,
            createdBy: values.createdBy,
            updatedBy: values.updatedBy,
          };
          api
            .put(`/Enquiry/replace`, { ...finalPayload })
            .then((res) => {
              navigate(-1);
              SuccessAlert("Enquiry updated successfully");
            })
            .catch((err) => {
              console.log(err, "error");
            });
          return;
        }}
      >
        {({ handleSubmit, handleChange, values, errors }) => (
          <Form
            noValidate={true}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Box display={"flex"} justifyContent="space-between">
              <Box>
                <Heading variant={"h1"} marginBottom={"20px"}>
                  {params.state.isEdit === true
                    ? "Edit Enquiry"
                    : "View Enquiry"}
                </Heading>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                gap={"10px"}
                marginBottom={"20px"}
              >
                <Button
                  variant={"warning"}
                  onClick={() => {
                    navigate(ROUTE_LINKS[MENU_NAMES.ENQUIRY]);
                  }}
                >
                  Discard
                </Button>
                {params.state.isEdit == true && (
                  <Button variant={"primary"} type="submit">
                    {isLoading ? (
                      <CircularProgress
                        isIndeterminate
                        size="24px"
                        color={colors.white}
                      />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                )}
              </Box>
            </Box>

            <Box
              display={"flex"}
              gap={"20px"}
              borderRadius={"10px"}
              flexDirection={"column"}
            >
              <Box
                padding={"20px 20px 0px"}
                borderRadius={"10px"}
                border={"1px solid #e6e6e6"}
              >
                <SimpleGrid
                  columns={{ base: "1", sm: "1", md: "3", lg: "3" }}
                  spacingX="30px"
                  spacingY="0px"
                >
                  <TextInput
                    type="text"
                    isRequired={true}
                    name="name"
                    value={values.name}
                    label={{
                      text: "Applicant Name",
                      colors: errors.name ? "red !important" : "",
                      variant: errors.name ? "errored" : "",
                    }}
                    onChange={handleChange}
                    placeholder={""}
                    variant={errors.name ? "errored" : "flushed"}
                    isReadOnly={isView}
                  />

                  <TextInput
                    type="date"
                    name="dob"
                    isDisabled={
                      selector.auth.login.RoleId == ROLES.ADMINISTRATOR
                        ? false
                        : true
                    }
                    isRequired={true}
                    onChange={handleChange}
                    placeholder={""}
                    value={convertDateFormat(values.dob, "yyyy-mm-dd")}
                    variant={errors.dob ? "errored" : "flushed"}
                    label={{
                      text: "DOB",
                      colors: errors.dob ? "red !important" : "",
                      variant: errors.dob ? "errored" : "",
                    }}
                    isReadOnly={isView}
                  />

                  <SelectInput
                    formControl={{ id: "enquiryStatusId", variant: "floating" }}
                    isRequired={true}
                    name="enquiryStatusId"
                    isReadOnly={isView}
                    value={values.enquiryStatusId}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    label={{
                      text: "Enquiry Status",
                      colors: errors.enquiryStatusId ? "red !important" : "",
                      variant: errors.enquiryStatusId ? "errored" : "",
                    }}
                    options={enquiryStatus}
                    variant={errors.enquiryStatusId ? "errored" : "flushed"}
                  />
                  <TextInput
                    type="text"
                    isRequired={true}
                    name="phoneNumber"
                    value={values.phoneNumber}
                    isDisabled={ROLES.ADMINISTRATOR}
                    label={{
                      text: "Phone Number",
                      colors: errors.phoneNumber ? "red !important" : "",
                      variant: errors.phoneNumber ? "errored" : "",
                    }}
                    onChange={handleChange}
                    placeholder={""}
                    variant={errors.phoneNumber ? "errored" : "flushed"}
                    isReadOnly={isView}
                  />
                  <TextInput
                    type="date"
                    name="followUpDate"
                    isRequired={true}
                    value={convertDateFormat(values.followUpDate, "yyyy-mm-dd")}
                    onChange={handleChange}
                    placeholder={""}
                    variant={errors.followUpDate ? "errored" : "flushed"}
                    label={{
                      text: "Follow up",
                      colors: errors.followUpDate ? "red !important" : "",
                      variant: errors.followUpDate ? "errored" : "",
                    }}
                    isReadOnly={isView}
                  />
                  <TextInput
                    type="text"
                    name="emailId"
                    isRequired={false}
                    value={values.emailId}
                    isDisabled={isView}
                    label={{
                      text: "Email",
                      colors: errors.emailId ? "red !important" : "",
                      variant: errors.emailId ? "errored" : "",
                    }}
                    onChange={handleChange}
                    placeholder={""}
                    variant={errors.emailId ? "errored" : "flushed"}
                    isReadOnly={isView}
                  />
                  <TextInput
                    type="text"
                    isRequired={false}
                    name="guardianName"
                    value={values.guardianName}
                    label={{
                      text: "Father/Guardian",
                      colors: errors.guardianName ? "red !important" : "",
                      variant: errors.guardianName ? "errored" : "",
                    }}
                    onChange={handleChange}
                    placeholder={""}
                    variant={errors.guardianName ? "errored" : "flushed"}
                    isReadOnly={isView}
                  />
                  <TextInput
                    type="text"
                    name="address"
                    placeholder={""}
                    isRequired={true}
                    isReadOnly={isView}
                    value={values.address}
                    variant={errors.address ? "errored" : "flushed"}
                    label={{
                      text: "Address",
                      colors: errors.address ? "red !important" : "",
                      variant: errors.address ? "errored" : "",
                    }}
                    onChange={handleChange}
                  />
                  <SwitchInput
                    height={"100%!important"}
                    placeholder={""}
                    isRequired={false}
                    isReadOnly={isView}
                    value={values.travelHistory}
                    name="travelHistory"
                    variant={errors.travelHistory ? "errored" : "flushed"}
                    label={{
                      text: "Travel History(if any)",
                      colors: errors.travelHistory ? "red !important" : "",
                      variant: errors.travelHistory ? "errored" : "",
                      fontSize: "12px",
                      marginBottom: "0px",
                    }}
                    formControl={{ id: "travelHistory" }}
                    onChange={handleChange}
                  />
                  {values.travelHistory && (
                    <TextInput
                      type="text"
                      name="travelRemark"
                      placeholder={""}
                      isReadOnly={isView}
                      isRequired={values.travelHistory}
                      isDisabled={!values.travelHistory}
                      value={values.travelRemark}
                      variant={errors.travelRemark ? "errored" : "flushed"}
                      label={{
                        text: "Travel Remark",
                        colors: errors.travelRemark ? "red !important" : "",
                        variant: errors.travelRemark ? "errored" : "",
                      }}
                      onChange={handleChange}
                    />
                  )}
                  <SwitchInput
                    placeholder={""}
                    isRequired={false}
                    isReadOnly={isView}
                    value={values.refusal}
                    name="refusal"
                    variant={errors.refusal ? "errored" : "flushed"}
                    label={{
                      text: "Refusal(if any)",
                      colors: errors.refusal ? "red !important" : "",
                      variant: errors.refusal ? "errored" : "",
                      fontSize: "12px",
                      textAlign: "center",
                      marginBottom: "0px",
                    }}
                    formControl={{ id: "refusal" }}
                    onChange={handleChange}
                  />
                  {values.refusal && (
                    <TextInput
                      type="text"
                      name="refusalRemark"
                      placeholder={""}
                      isReadOnly={isView}
                      isRequired={values.refusal}
                      isDisabled={!values.refusal}
                      value={values.refusalRemark}
                      variant={errors.refusalRemark ? "errored" : "flushed"}
                      label={{
                        text: "Refusal Remark",
                        colors: errors.refusalRemark ? "red !important" : "",
                        variant: errors.refusalRemark ? "errored" : "",
                      }}
                      onChange={handleChange}
                    />
                  )}
                </SimpleGrid>
              </Box>
              <SimpleGrid columns={{ base: "1", md: "2", lg: "2" }} spacing={5}>
                <Accordion defaultIndex={[0]} allowMultiple>
                  <AccordionItem
                    borderRadius={"10px"}
                    border={"1px solid #e6e6e6"}
                    height={"100%"}
                  >
                    <AccordionButton backgroundColor={"#ebebeb"}>
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        fontWeight={"600"}
                      >
                        Education Details
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel p={4}>
                      <Box>
                        <SimpleGrid
                          columns={{ base: "1", sm: "1", md: "1", lg: "2" }}
                          spacingX="30px"
                          spacingY="0px"
                        >
                          <Box w={"100%"} display={"flex"} gap={"10px"}>
                            <Box w={"50%"}>
                              <TextInput
                                type="number"
                                name="ieltsScore"
                                placeholder={""}
                                isRequired={false}
                                isReadOnly={isView}
                                value={values.ieltsScore}
                                variant={errors.address ? "errored" : "flushed"}
                                label={{
                                  text: "Ilets Score",
                                  colors: errors.ieltsScore
                                    ? "red !important"
                                    : "",
                                  variant: errors.ieltsScore ? "errored" : "",
                                }}
                                onChange={handleChange}
                                width={"30px"}
                              />
                            </Box>
                            <Box w={"50%"}>
                              <Box
                                display={"flex"}
                                gap={"5px"}
                                justifyContent={"end"}
                              >
                                <TextInput
                                  type="number"
                                  name="listeningScore"
                                  alignItems={"center"}
                                  isRequired={false}
                                  width={"30px"}
                                  placeholder={""}
                                  value={values.listeningScore}
                                  label={{
                                    text: "L",
                                    colors: errors.listeningScore
                                      ? "red !important"
                                      : "",
                                    variant: errors.listeningScore
                                      ? "errored"
                                      : "",
                                  }}
                                  onChange={handleChange}
                                  variant={
                                    errors.listening ? "errored" : "flushed"
                                  }
                                  isReadOnly={isView}
                                />
                                <TextInput
                                  type="number"
                                  name="writingScore"
                                  alignItems={"center"}
                                  isRequired={false}
                                  width={"30px"}
                                  placeholder={""}
                                  value={values.writingScore}
                                  label={{
                                    text: "W",
                                    colors: errors.writingScore
                                      ? "red !important"
                                      : "",
                                    variant: errors.writingScore
                                      ? "errored"
                                      : "",
                                  }}
                                  onChange={handleChange}
                                  variant={
                                    errors.writingScore ? "errored" : "flushed"
                                  }
                                  isReadOnly={isView}
                                />
                                <TextInput
                                  type="number"
                                  name="readingScore"
                                  alignItems={"center"}
                                  isRequired={false}
                                  width={"30px"}
                                  placeholder={""}
                                  value={values.readingScore}
                                  label={{
                                    text: "R",
                                    colors: errors.readingScore
                                      ? "red !important"
                                      : "",
                                    variant: errors.readingScore
                                      ? "errored"
                                      : "",
                                  }}
                                  onChange={handleChange}
                                  variant={
                                    errors.readingScore ? "errored" : "flushed"
                                  }
                                  isReadOnly={isView}
                                />
                                <TextInput
                                  type="number"
                                  name="speakingScore"
                                  alignItems={"center"}
                                  isRequired={false}
                                  width={"30px"}
                                  placeholder={""}
                                  value={values.speakingScore}
                                  label={{
                                    text: "S",
                                    colors: errors.speakingScore
                                      ? "red !important"
                                      : "",
                                    variant: errors.speakingScore
                                      ? "errored"
                                      : "",
                                  }}
                                  onChange={handleChange}
                                  variant={
                                    errors.speakingScore ? "errored" : "flushed"
                                  }
                                  isReadOnly={isView}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <SelectInput
                            formControl={{
                              id: "qualStreamId",
                              variant: "floating",
                            }}
                            name="qualStreamId"
                            isRequired={false}
                            isReadOnly={isView}
                            value={values.qualStreamId}
                            label={{
                              text: "Last Qualification",
                              colors: errors.qualStreamId
                                ? "red !important"
                                : "",
                              variant: errors.qualStreamId ? "errored" : "",
                            }}
                            options={qualification}
                            variant={
                              errors.qualStreamId ? "errored" : "flushed"
                            }
                            onChange={handleChange}
                          />
                          <TextInput
                            type="number"
                            name="passoutYear"
                            isRequired={false}
                            isReadOnly={isView}
                            placeholder={""}
                            value={values.passoutYear}
                            label={{
                              text: "Passout year",
                            }}
                            onChange={handleChange}
                          />
                          <SelectInput
                            formControl={{
                              id: "boardId",
                              variant: "floating",
                            }}
                            isRequired={false}
                            name="boardId"
                            isReadOnly={isView}
                            value={values.boardId}
                            label={{
                              text: "Board University",
                              colors: errors.boardId ? "red !important" : "",
                              variant: errors.boardId ? "errored" : "",
                            }}
                            onChange={handleChange}
                            options={board}
                            variant={errors.boardId ? "errored" : "flushed"}
                          />
                          <TextInput
                            type="number"
                            name="lastPercentage"
                            placeholder={""}
                            isRequired={false}
                            isReadOnly={isView}
                            value={values.lastPercentage}
                            label={{
                              text: "Last lastPercentage(%)",
                            }}
                            onChange={handleChange}
                          />
                        </SimpleGrid>
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
                <Accordion defaultIndex={[0]} allowMultiple>
                  <AccordionItem
                    borderRadius={"10px"}
                    border={"1px solid #e6e6e6"}
                  >
                    <AccordionButton backgroundColor={"#ebebeb"}>
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        fontWeight={"600"}
                      >
                        Office Details
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel p={4}>
                      <Box>
                        <SimpleGrid
                          columns={{ base: "1", sm: "1", md: "1", lg: "2" }}
                          spacingX="30px"
                          spacingY="0px"
                        >
                          <SelectInput
                            formControl={{
                              id: "visaCountryId",
                              variant: "floating",
                            }}
                            name="visaCountryId"
                            isRequired={true}
                            isReadOnly={isView}
                            value={values.visaCountryId}
                            label={{
                              text: "Visa Country",
                              colors: errors.visaCountryId
                                ? "red !important"
                                : "",
                              variant: errors.visaCountryId ? "errored" : "",
                            }}
                            onChange={handleChange}
                            options={visaCountry}
                            variant={
                              errors.visaCountryId ? "errored" : "flushed"
                            }
                          />
                          <SelectInput
                            formControl={{
                              id: "counsellorId",
                              variant: "floating",
                            }}
                            name="counsellorId"
                            isRequired={true}
                            isReadOnly={isView}
                            value={values.counsellorId}
                            label={{
                              text: "Counsellor",
                              colors: errors.counsellorId
                                ? "red !important"
                                : "",
                              variant: errors.counsellorId ? "errored" : "",
                            }}
                            options={counsellorState}
                            variant={
                              errors.counsellorId ? "errored" : "flushed"
                            }
                            onChange={handleChange}
                          />
                          <SelectInput
                            formControl={{
                              id: "telecallerId",
                              variant: "floating",
                            }}
                            name="telecallerId"
                            isRequired={false}
                            isReadOnly={isView}
                            value={values.telecallerId}
                            label={{
                              text: "Tele Caller",
                              colors: errors.telecallerId
                                ? "red !important"
                                : "",
                              variant: errors.telecallerId ? "errored" : "",
                            }}
                            options={telecallerState}
                            variant={
                              errors.telecallerId ? "errored" : "flushed"
                            }
                            onChange={handleChange}
                          />
                          <SelectInput
                            formControl={{
                              id: "visaTypeId",
                              variant: "floating",
                            }}
                            name="visaTypeId"
                            isRequired={true}
                            isReadOnly={isView}
                            value={values.visaTypeId}
                            label={{
                              text: "Visa Type",
                              colors: errors.visaTypeId ? "red !important" : "",
                              variant: errors.visaTypeId ? "errored" : "",
                            }}
                            options={visaType}
                            variant={errors.visaTypeId ? "errored" : "flushed"}
                            onChange={handleChange}
                          />
                          <SelectInput
                            formControl={{
                              id: "referenceId",
                              variant: "floating",
                            }}
                            name="referenceId"
                            isRequired={true}
                            isReadOnly={isView}
                            value={values.referenceId}
                            label={{
                              text: "Reference",
                              colors: errors.referenceId
                                ? "red !important"
                                : "",
                              variant: errors.referenceId ? "errored" : "",
                            }}
                            options={reference}
                            variant={errors.referenceId ? "errored" : "flushed"}
                            onChange={handleChange}
                            isDisabled={isView}
                          />
                          <TextArea
                            name="remark"
                            isReadOnly={isView}
                            isRequired={true}
                            value={values.remark}
                            placeholder={""}
                            label={{
                              text: "Remarks",
                              colors: errors.remark ? "red !important" : "",
                              variant: errors.remark ? "errored" : "",
                            }}
                            onChange={handleChange}
                            variant={errors.remark ? "errored" : "flushed"}
                          />
                        </SimpleGrid>
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </SimpleGrid>
              <Accordion defaultIndex={[0]} allowMultiple>
                <AccordionItem
                  borderRadius={"10px"}
                  border={"1px solid #e6e6e6"}
                  overflow={"hidden"}
                >
                  <AccordionButton
                    backgroundColor={colors.gray.gray300}
                    color={colors.black}
                  >
                    <Box as="span" flex="1" textAlign="left" fontWeight={"600"}>
                      Remark History
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <Box
                      borderRadius={"10px"}
                      marginTop={"0px"}
                      padding={"0px"}
                    >
                      <Grid
                        name={"enquiry_remarks"}
                        columns={GRID_COLUMNS.ENQUIRY_REMARK_LOGS}
                        data={enquiryRemarks}
                        hasAction={false}
                        hasColumnDrop={false}
                        permission={props}
                      />
                    </Box>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Box>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default AddEnquiry;
