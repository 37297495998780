import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { colors } from "../../theme/styles/colors";
import Pagination from "../../theme/components/pagination/pagination";
import { MENU_NAMES, PAGE_SIZE, ROLES } from "../../core/utils/constants";
import { GRID_COLUMNS } from "../../core/utils/grid-columns";
import { ROUTE_LINKS } from "../../core/utils/route-links";
import api from "../../core/api";
import { CREATE_RESPONSE } from "../../core/utils/common-function";
import { useSelector } from "react-redux";
import Grid from "../../theme/components/grid/grid";

const SystemUsers = (props) => {
  const navigate = useNavigate();
  const selector = useSelector((_) => _);
  const hasExport =
    selector.auth.login.RoleId == ROLES.SYSTEM_ADMINISTRATOR ||
    selector.auth.login.RoleId == ROLES.ADMINISTRATOR ||
    selector.auth.login.RoleId == ROLES.BRANCH_HEAD;
  const hasDelete =
    selector.auth.login.RoleId == ROLES.SYSTEM_ADMINISTRATOR ||
    selector.auth.login.RoleId == ROLES.ADMINISTRATOR;
  const [columns, setColumns] = useState(
    GRID_COLUMNS.USER.filter((x) => x.Active && x.Visibility)
  );
  const [totalCount, setTotalCount] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [data, setData] = useState([]);

  const onChange = (page) => {
    props.setSystemUserPayload({
      ...props.systemUserPayload,
      pageIndex: page,
    });
  };

  const getAllRecords = () => {
    api
      .post("/User/getSystemUsers", props.systemUserPayload)
      .then((res) => {
        setTotalCount(res.data.data.total);
        setDataSource(res.data.data.data);
        setData(CREATE_RESPONSE(GRID_COLUMNS.USER, res.data.data.data));
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  useEffect(() => {
    setData(CREATE_RESPONSE(columns, dataSource));
  }, [columns]);

  useEffect(() => {
    if (props.tabIndex == 1 && props.systemUserPayload.branchId != "")
      getAllRecords();
  }, [props.systemUserPayload, selector.branch.userBranchId, props.tabIndex]);

  return (
    <div>
      <Grid
        name={"system_level_users"}
        columns={GRID_COLUMNS.USER}
        aColumns={columns}
        data={data}
        permission={props.permission}
        hasDelete={hasDelete}
        hasExport={hasExport}
        setColumns={(e) => setColumns(e)}
        viewRoute={ROUTE_LINKS[MENU_NAMES.VIEW_USER]}
        editRoute={ROUTE_LINKS[MENU_NAMES.EDIT_USER]}
        setRecordId={(id, record) => {
          props.setUserId(id);
          if (record.isDelete) props.setIsDeleteOpen(true);
        }}
      />
      <Pagination
        className="pagination-bar"
        currentPage={props.systemUserPayload.pageIndex}
        totalCount={totalCount}
        pageSize={props.systemUserPayload.pageSize}
        onPageChange={(page) =>
          props.setSystemUserPayload({
            ...props.systemUserPayload,
            pageIndex: page,
          })
        }
        handleRowStateChange={(e) => {
          props.setSystemUserPayload({
            ...props.systemUserPayload,
            pageIndex: 1,
            pageSize: e.target.value,
          });
        }}
        activeBackgroundColor={colors.primaryColor}
        activeTextColor={colors.gray.gray100}
        onChange={onChange}
      />
    </div>
  );
};
export default SystemUsers;
