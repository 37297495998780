import React, { useEffect } from "react";
import {
  Badge,
  Box,
  Container,
  Heading,
  MenuList,
  MenuItem,
  Tbody,
  MenuButton,
  Menu,
  TableContainer,
  Td,
  Th,
  Tr,
  Table,
  Thead,
  useDisclosure,
  SimpleGrid,
  Collapse,
  Text,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Modal,
  ModalOverlay,
  ModalFooter,
  CircularProgress,
} from "@chakra-ui/react";

import {
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from "@chakra-ui/react";
import { Checkbox, CheckboxGroup } from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { Navigate, useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  ChevronDownIcon,
  CloseIcon,
  HamburgerIcon,
  SearchIcon,
} from "@chakra-ui/icons";
import { colors } from "../../theme/styles/colors";
import BreadcrumbBox from "../../theme/components/breadcrumb";
import TextInput from "../../theme/components/input/text-input";
import Pagination from "../../theme/components/pagination/pagination";
import { GET_BREADCRUMB_ROUTES } from "../../core/utils/navbar-items";
import {
  EXPORT_FILE_EXTENSION,
  EXPORT_FILE_TYPE,
  MENU_NAMES,
  PAGE_SIZE,
  ROLES,
} from "../../core/utils/constants";
import { GRID_COLUMNS } from "../../core/utils/grid-columns";
import { ROUTE_LINKS } from "../../core/utils/route-links";
import SelectInput from "../../theme/components/input/select-input";
import { BiExport } from "react-icons/bi";
import { GoFilter } from "react-icons/go";
import { IoMdAddCircleOutline } from "react-icons/io";
import MultiSelectInput from "../../theme/components/input/multiselect-input";
import useTableHeightFix from "../../core/hooks/useTableHeightFix";
import {
  EXPORT_TO_EXCEL,
  getFilterCount,
  convertDateFormat,
  createFilterPayload,
  deleteById,
  getAll,
  getById,
  handleFilterChange,
  handleResetState,
  CREATE_RESPONSE,
} from "../../core/utils/common-function";
import api from "../../core/api";
import { AiFillDelete, AiOutlineEye } from "react-icons/ai";
import { GrFormView } from "react-icons/gr";
import * as yup from "yup";
import { FiEdit } from "react-icons/fi";
import { BsThreeDotsVertical } from "react-icons/bs";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Form, Formik } from "formik";
import DeleteModal from "../../theme/components/modals/delete-modal";
import SwitchInput from "../../theme/components/input/switch-input";
import SCHEMA_VALIDATOR from "../../core/utils/schema-validator";
import { SuccessAlert } from "../../core/utils/common-sweet-alert";
import { useSelector } from "react-redux";
import Grid from "../../theme/components/grid/grid";
import { GRID_OTHER_ACTIONS } from "../../core/utils/grid-other-action";

const Branch = (props) => {
  const [topHeight, resizeElementHeight, windowHeight] = useTableHeightFix();
  const [state, setState] = useState();
  const [district, setDistrict] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [tableRowData, setTableRowData] = useState([]);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState(0);
  const navigate = useNavigate();
  const [totalCount, setTotalCount] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [branchId, setBranchId] = useState(null);
  const selector = useSelector((_) => _);
  const [show, setShow] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [data, setData] = useState([]);
  const hasExport =
    selector.auth.login.RoleId == ROLES.SYSTEM_ADMINISTRATOR || 
selector.auth.login.RoleId == ROLES.ADMINISTRATOR ||
    selector.auth.login.RoleId == ROLES.BRANCH_HEAD;
  const [columns, setColumns] = useState(
    GRID_COLUMNS.BRANCH.filter((x) => x.Active && x.Visibility)
  );
  const handleToggle = () => {
    setShow(!show);
  };
  const { GUID_VALIDATOR, BOOLEAN_VALIDATOR, STRING_VALIDATOR } =
    SCHEMA_VALIDATOR;

  const [apiPayload, setAPIPayload] = useState({
    filter: [],
    pageSize: PAGE_SIZE,
    pageIndex: 1,
    branchId: selector.branch.userBranchId,
  });
  const [payload, setPayload] = useState({
    filter: [],
    pageSize: 0,
    pageIndex: 0,
    branchId: selector.branch.userBranchId,
  });
  const [filterState, setFilterState] = useState({
    name: "",
    isActive: null,
    stateId: null,
    districtId: null,
  });
  const [initialState, setInitialState] = useState({
    name: "",
    isActive: false,
    stateId: null,
    districtId: null,
    branchId: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const onChange = (page) => {
    setAPIPayload({
      ...apiPayload,
      pageIndex: page,
    });
  };

  const getAllRecords = () => {
    api
      .post("/Branch/getAll", apiPayload)
      .then((res) => {
        setTotalCount(res.data.data.total);
        setDataSource(res.data.data.data);
        setData(CREATE_RESPONSE(GRID_COLUMNS.BRANCH, res.data.data.data));
      })
      .catch((err) => {});
  };

  const validationSchema = yup.object({
    name: STRING_VALIDATOR,
    stateId: GUID_VALIDATOR,
    districtId: GUID_VALIDATOR,
    isActive: BOOLEAN_VALIDATOR,
  });

  const getAllPromises = () => {
    const district = getAll("District", payload);
    const state = getAll("State", payload);
    Promise.all([district, state])
      .then((res) => {
        const [dist, stat, role, user] = res;
        const district = dist.data.data.map((dist) => ({
          text: dist.name,
          value: dist.districtId,
        }));
        const states = stat.data.data.map((sta) => ({
          text: sta.name,
          value: sta.stateId,
        }));
        setDistrict(district);
        setState(states);
      })
      .catch((err) => {});
  };
  const resetFilterState = () => {
    setAPIPayload({
      ...apiPayload,
      filter: [],
      pageIndex: 1,
      pageSize: PAGE_SIZE,
    });
    handleResetState(filterState, setFilterState);
    setActiveFilter(0);
    // getAllRecords();
  };
  const getRecord = async () => {
    try {
      if (branchId) {
        let record = (await getById("branch", branchId)).data;
        setInitialState({
          isActive: record.isActive,
          Name: record.name,
          State: record.stateId,
          District: record.districtId,
          ...record,
        });
        onOpen();
      }
    } catch (error) {}
  };
  useEffect(() => {
    getRecord();
  }, [isEdit, isView]);

  useEffect(() => {
    document.title = "Branch | Digital Filing";
    if (
      apiPayload.branchId != "" &&
      apiPayload.branchId == selector.branch.userBranchId
    ) {
      getAllRecords();
      getAllPromises();
    } else {
      setAPIPayload({
        ...apiPayload,
        branchId: selector.branch.userBranchId,
      });
    }
  }, [apiPayload, selector.branch.userBranchId, payload]);

  useEffect(() => {
    setData(CREATE_RESPONSE(columns, dataSource));
  }, [columns]);

  useEffect(() => {
    resizeElementHeight(
      document.getElementsByClassName("chakra-table__container")[0]
    );
  }, [windowHeight]);

  const onModelClose = () => {
    setBranchId(null);
    setIsEdit(false);
    setIsView(false);
    onClose();
    setInitialState({
      isActive: false,
      name: "",
      stateId: "",
      districtId: "",
      branchId: "",
    });
  };
  const onDelete = async () => {
    await deleteById("branch", branchId);
    setIsDeleteOpen(false);
    getAllRecords();
    onModelClose();
  };
  const handlePermissionDelete = (index) => {
    tableRowData.splice(index, 1);
    setTableRowData([...tableRowData]);
  };
  return (
    <Box>
      <DeleteModal
        isOpen={isDeleteOpen}
        onDelete={onDelete}
        onClose={() => {
          setIsDeleteOpen(false);
        }}
      />
      <Container maxW="container.xll" padding={"20px"} paddingBottom={"0px"}>
        <Box>
          <BreadcrumbBox
            route={GET_BREADCRUMB_ROUTES(MENU_NAMES.BRANCH, true)}
          />
        </Box>
        <Box
          color={colors.white}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          marginBottom={"20px"}
        >
          <Box>
            <Heading variant={"h1"}>Branch</Heading>
          </Box>

          <Box display={"flex"} alignItems={"center"} gap={"20px"}>
            <Button onClick={handleToggle} variant={"secondary"}>
              <GoFilter fontSize={"20px"} />
              <Text marginLeft={"5px"} className="btnText">
                Filter
              </Text>
              <Box
                position={"absolute"}
                top={"-19px"}
                right={"-1"}
                backgroundColor={colors.primaryColor}
                color={colors.white}
                borderRadius={"16px"}
                padding={"0px"}
                height={"30px"}
                width={"30px"}
                lineHeight={"30px"}
              >
                {activeFilter}
              </Box>
            </Button>
            {props.hasCreate && (
              <Button
                fontSize={{ base: "12px", sm: "13px", md: "14px", lg: "14px" }}
                variant={"primary"}
                onClick={() => {
                  navigate(ROUTE_LINKS[MENU_NAMES.ADD_BRANCH]);
                }}
              >
                <IoMdAddCircleOutline fontSize={"20px"} />
                <Text marginLeft={"5px"} className="btnText">
                  Create New
                </Text>
              </Button>
            )}
          </Box>
        </Box>
        <Box
          backgroundColor={colors.white}
          boxShadow={"0px 0px 35px 0px #F5F5F5"}
          borderRadius={"10px"}
          marginBottom={"20px"}
        >
          <Collapse in={show}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const payloadArr = createFilterPayload(filterState);
                const filterSum = getFilterCount(filterState);
                setActiveFilter(filterSum);
                setAPIPayload({
                  ...apiPayload,
                  filter: payloadArr,
                  pageSize: PAGE_SIZE,
                  pageIndex: 1,
                });
              }}
            >
              <SimpleGrid
                columns={{ base: "1", sm: "1", md: "4", lg: "4" }}
                spacingX="40px"
                padding={"20px"}
                paddingBottom={"0px"}
              >
                <TextInput
                  type="text"
                  placeholder={""}
                  name="Name"
                  value={filterState.Name?.value}
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState)
                  }
                  marginTop="20px"
                  formControl={{ id: "name" }}
                  label={{ text: "Name" }}
                />
                <SelectInput
                  formControl={{ id: "isActive", variant: "floating" }}
                  isRequired={false}
                  isReadOnly={false}
                  value={filterState.IsActive?.value}
                  name="IsActive"
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState)
                  }
                  label={{
                    text: "Status",
                  }}
                  options={[
                    { text: "Active", value: true },
                    { text: "Inactive", value: false },
                  ]}
                />
                <SelectInput
                  formControl={{ id: "stateId", variant: "floating" }}
                  isReadOnly={false}
                  value={filterState?.StateId?.value}
                  name="StateId"
                  onChange={(e) => {
                    handleFilterChange(e, filterState, setFilterState);
                    api
                      .get(`District/getByState/${e.target.value}`)
                      .then((res) => {
                        const Districts = res.data.data.map((dist) => ({
                          text: dist.name,
                          value: dist.districtId,
                        }));
                        setDistrict(Districts);
                      })
                      .catch((err) => {
                        console.log(err, "err");
                      });
                  }}
                  label={{
                    text: "State",
                  }}
                  options={state}
                />
                <SelectInput
                  formControl={{ id: "districtId", variant: "floating" }}
                  isReadOnly={false}
                  isDisabled={!filterState?.StateId?.value}
                  name="DistrictId"
                  value={filterState?.DistrictId?.value}
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState)
                  }
                  label={{
                    text: "District",
                  }}
                  options={district}
                />
                {/* <SelectInput
                  formControl={{ id: "stateId", variant: "floating" }}
                  isRequired={false}
                  isReadOnly={false}
                  value={filterState.stateId?.value}
                  name="stateId"
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState)
                  }
                  label={{
                    text: "State",
                  }}
                  options={state}
                />
                <SelectInput
                  formControl={{ id: "districtId", variant: "floating" }}
                  isRequired={false}
                  isReadOnly={false}
                  value={filterState.districtId?.value}
                  name="districtId"
                  onChange={(e) =>
                    handleFilterChange(e, filterState, setFilterState)
                  }
                  label={{
                    text: "District",
                  }}
                  options={district}
                /> */}
              </SimpleGrid>
              <Box
                display={"flex"}
                justifyContent={"end"}
                paddingRight={"20px"}
                paddingBottom={"20px"}
              >
                <Button
                  fontSize={{
                    base: "12px",
                    sm: "13px",
                    md: "14px",
                    lg: "14px",
                  }}
                  variant={"primary"}
                  type="submit"
                >
                  Search
                </Button>
                <Button
                  fontSize={{
                    base: "12px",
                    sm: "13px",
                    md: "14px",
                    lg: "14px",
                  }}
                  variant={"warning"}
                  marginLeft={"10px"}
                  isDisabled={activeFilter > 0 ? false : true}
                  onClick={resetFilterState}
                >
                  Reset
                </Button>
              </Box>
            </form>
          </Collapse>
        </Box>

        <Grid
          name={"branch"}
          columns={GRID_COLUMNS.BRANCH}
          aColumns={columns}
          data={data}
          permission={props}
          hasDelete={true}
          hasExport={hasExport}
          setColumns={(e) => setColumns(e)}
          isPopupComponent={true}
          otherActions={GRID_OTHER_ACTIONS.BRANCH}
          setRecordId={(id, props) => {
            setBranchId(id);
            if (props.isView) {
              setIsView(true);
              setIsEdit(false);
            } else if (props.isEdit) {
              setIsView(false);
              setIsEdit(true);
            } else if (props.isDelete) setIsDeleteOpen(true);
            else if (props.viewPermission)
              navigate(ROUTE_LINKS[MENU_NAMES.VIEW_PERMISSIONS], {
                state: {
                  branchId: branchId,
                },
              });
          }}
        />

        <Pagination
          className="pagination-bar"
          currentPage={apiPayload.pageIndex}
          totalCount={totalCount}
          pageSize={apiPayload.pageSize}
          onPageChange={(page) =>
            setAPIPayload({
              ...apiPayload,
              pageIndex: page,
            })
          }
          handleRowStateChange={(e) => {
            setAPIPayload({
              ...apiPayload,
              pageIndex: 1,
              pageSize: e.target.value,
            });
          }}
          activeBackgroundColor={colors.primaryColor}
          activeTextColor={colors.gray.gray100}
          onChange={onChange}
        />
      </Container>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        minWidth={"20%"}
        isCentered
      >
        <ModalOverlay />
        <Formik
          validationSchema={validationSchema}
          initialValues={initialState}
          enableReinitialize={true}
          onSubmit={(values) => {
            let finalPayload = {
              name: values.name,
              isActive: values.isActive,
              stateId: values.stateId,
              districtId: values.districtId,
              branchId: values.branchId,
            };
            api
              .put(`/Branch/replace`, { ...finalPayload })
              .then((res) => {
                SuccessAlert("Record creted successfully");
                setIsEdit(false);
                setIsView(false);
                onModelClose();
                getAllRecords();
              })
              .catch((err) => {
                console.log(err, "err");
              });
          }}
        >
          {({ handleSubmit, handleChange, values, errors }) => (
            <Form
              noValidate={true}
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <ModalContent>
                <ModalHeader borderBottom={"1px solid #EDEDED"}>
                  {" "}
                  {isEdit
                    ? "Update Branch "
                    : isView
                    ? "View Branch "
                    : "Create New Branch "}
                </ModalHeader>
                <ModalCloseButton
                  bg={colors.primaryColor}
                  color={colors.white}
                  position={"absolute"}
                  top={"15px"}
                />
                <ModalBody padding={"20px"}>
                  <SimpleGrid
                    columns={{ base: "1", sm: "1", md: "1", lg: "1" }}
                    spacingX="-3px"
                    spacingY="-6px"
                    gap={"10px"}
                  >
                    <TextInput
                      type="text"
                      isRequired={true}
                      name="name"
                      value={values.name}
                      label={{
                        text: "Name",
                        colors: errors.name ? "red !important" : "",
                        variant: errors.name ? "errored" : "",
                      }}
                      onChange={(e) => handleChange(e)}
                      placeholder={""}
                      variant={errors.name ? "errored" : "flushed"}
                      isReadOnly={isView}
                    />
                    <SelectInput
                      formControl={{ id: "stateId", variant: "floating" }}
                      isRequired={true}
                      isReadOnly={isView}
                      value={values.stateId}
                      label={{
                        text: "State",
                        colors: errors.stateId ? "red !important" : "",
                        variant: errors.stateId ? "errored" : "",
                      }}
                      options={state}
                      variant={errors.stateId ? "errored" : "flushed"}
                      onChange={(e) => handleChange(e)}
                    />
                    <SelectInput
                      formControl={{ id: "districtId", variant: "floating" }}
                      isRequired={true}
                      isReadOnly={isView}
                      value={values.districtId}
                      label={{
                        text: "District",
                        colors: errors.districtId ? "red !important" : "",
                        variant: errors.districtId ? "errored" : "",
                      }}
                      options={district}
                      variant={errors.districtId ? "errored" : "flushed"}
                      onChange={(e) => handleChange(e)}
                    />

                    <SwitchInput
                      placeholder={""}
                      isRequired={true}
                      isReadOnly={isView}
                      value={values.isActive}
                      name="isActive"
                      variant={errors.isActive ? "errored" : "flushed"}
                      label={{
                        text: "Active",
                        colors: errors.isActive ? "red !important" : "",
                        variant: errors.isActive ? "errored" : "",
                        fontSize: "12px",
                      }}
                      formControl={{ id: "isActive" }}
                      onChange={handleChange}
                    />
                  </SimpleGrid>
                </ModalBody>
                <ModalFooter
                  justifyContent={"space-between"}
                  borderTop={"1px solid #EDEDED "}
                >
                  <Button variant={"secondaryOutline"} onClick={onClose}>
                    {isView ? "Back" : "Discard"}
                  </Button>
                  {!isView && (
                    <Button
                      variant={"primary"}
                      color={colors.white}
                      type="submit"
                    >
                      {isLoading ? (
                        <CircularProgress
                          isIndeterminate
                          size="24px"
                          color={colors.white}
                        />
                      ) : isEdit ? (
                        "Save"
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  )}
                </ModalFooter>
              </ModalContent>
            </Form>
          )}
        </Formik>
      </Modal>
    </Box>
  );
};
export default Branch;
