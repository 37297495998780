import {
  Box,
  Button,
  Heading,
  SimpleGrid,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  CircularProgress,
  RadioGroup,
  Stack,
  Radio,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Container } from "chakra-paginator";
import React from "react";
import TextInput from "../../theme/components/input/text-input";
import { colors } from "../../theme/styles/colors";
import BreadcrumbBox from "../../theme/components/breadcrumb";
import { useLocation, useNavigate } from "react-router-dom";
import { GET_BREADCRUMB_ROUTES } from "../../core/utils/navbar-items";
import {
  FILE_TYPE,
  INTAKE,
  MENU_NAMES,
  PAGE_SIZE,
  ROLES,
} from "../../core/utils/constants";
import SelectInput from "../../theme/components/input/select-input";
import { ROUTE_LINKS } from "../../core/utils/route-links";
import { Form, Formik } from "formik";
import TextArea from "../../theme/components/input/text-area";
import api from "../../core/api";
import {
  convertDateFormat,
  getAll,
  getById,
  getUsersByRole,
} from "../../core/utils/common-function";
import { SuccessAlert } from "../../core/utils/common-sweet-alert";
import { IMMIGRATION_PAYLOAD } from "./immigration-payload";
import IMMIGRATION_VALIDATION_SCHEMA from "./immigration-validation-schema";
import SwitchInput from "../../theme/components/input/switch-input";
import SCHEMA_VALIDATOR from "../../core/utils/schema-validator";
import * as yup from "yup";

const AddImmigration = () => {
  const selector = useSelector((_) => _);
  const navigate = useNavigate();
  const [checkedItems, setCheckedItems] = React.useState([false, false]);
  const allChecked = checkedItems.every(Boolean);
  const isIndeterminate = checkedItems.some(Boolean) && !allChecked;
  const params = useLocation();
  const isEdit = params.state.isEdit;
  const isView = params.state.isView;
  const isEnquiry = params.state.isEnquiry;
  const isGradeReadonly =
    selector.auth.login.RoleId != ROLES.SYSTEM_ADMINISTRATOR &&
    selector.auth.login.RoleId != ROLES.ADMINISTRATOR &&
    selector.auth.login.RoleId != ROLES.FILLING_HEAD &&
    selector.auth.login.RoleId != ROLES.BRANCH_HEAD;

  const [isLoading, setIsLoading] = useState(false);
  const [branchId, setBranchId] = useState(selector.branch.userBranchId);
  const [district, setDistrict] = useState([]);
  const [counsellorState, setCounsellorState] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [city, setCity] = useState([]);
  const [countries, setCountries] = useState([]);
  const [bank, setBank] = useState([]);
  const [visaTypes, setVisaTypes] = useState([]);
  const [fileType, setFileType] = useState([]);
  const [college, setCollege] = useState([]);
  const [reference, setReference] = useState([]);
  const [fund, setFund] = useState([]);
  const [course, setCourse] = useState([]);
  const [qualStream, setQualStream] = useState([]);
  const [grade, setGrade] = useState([]);
  const [board, setBoard] = useState([]);
  const [state, setState] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [visacountryId, setVisaCountryId] = useState(null);
  const [tableRowData, setTableRowData] = useState([]);
  const [value, setValue] = React.useState("1");
  const onChange = (page) => {
    setAPIPayload({
      ...apiPayload,
      pageIndex: page,
    });
  };
  const [apiPayload, setAPIPayload] = useState({
    filter: [],
    pageSize: PAGE_SIZE,
    pageIndex: 1,
  });
  const [initialState, setInitialState] = useState({
    emailId: "",
    applicantName: "",
    boardId: "",
    passportNo: "",
    districtId: "",
    alternateNo: "",
    rating: "",
    stateId: null,
    qualStreamId: "",
    father: "",
    date: "",
    address: "",
    phoneNumber: "",
    dob: "",
    file: "",
    percentage: 0,
    passoutYear: 0,
    ieltsScore: 0,
    listeningScore: 0,
    readingScore: 0,
    writingScore: 0,
    speakingScore: 0,
    // remark: "",
    enquiryStatusId: 0,
    lastQualificationId: 0,
    file: null,
    passportDoc: 1,
    tenthDoc: 1,
    twelfthDoc: 1,
    graduationDoc: 1,
    degreeDoc: 1,
    ieltsPTEDoc: 1,
    visaTypeId: null,
    visaCollegeId: "",
    referenceId: "",
    documentRemark: "",
    specialRemark: "",
    courseId: "",
    caseStatusId: 1,
    fileModeId: 0,
    scoreValidUpTo: "",
    //gradeId: "",
    grade: "",
    visaCountryId: null,
    visaCityId: null,
    fileNumber: "",
  });
  const [singleRecord, setSingleRecord] = useState({});
  const [payload, setPayload] = useState({
    filter: [],
    pageSize: 0,
    pageIndex: 0,
  });

  const { STRING_VALIDATOR, POSITIVE_INTEGER, GUID_VALIDATOR } =
    SCHEMA_VALIDATOR;
  const validationSchema = yup.object({
    name: STRING_VALIDATOR,
    visaCountryId: GUID_VALIDATOR,
  });
  const getImmigrationFile = async () => {
    const singleUser = (await getById("ImmigrationFile", params.state.id)).data;
    setSingleRecord(singleUser);
    setInitialState({
      caseStatusId: singleUser.caseStatusId,
      fileNumber: singleUser.fileNumber,
      dob: convertDateFormat(singleUser.dob, "yyyy-mm-dd"),
      emailId: singleUser.emailId,
      applicantName: singleUser.name,
      boardId: singleUser.boardId,
      visaTypeId: singleUser.visaTypeId,
      // gradeId: singleUser.gradeId,
      grade: singleUser.grade,
      passportNo: singleUser.passportNumber,
      districtId: singleUser.districtId,
      alternateNo: singleUser.alternateNumber,
      fundTypeId: singleUser.fundTypeId,
      stateId: singleUser.stateId,
      qualStreamId: singleUser.qualStreamId,
      father: singleUser.guardianName,
      courseId: singleUser.visaCourseId,
      referenceId: singleUser.referenceId,
      visaCountryId: singleUser.visaCountryId,
      visaCityId: singleUser.visaCityId,
      visaCollegeId: singleUser.visaCollegeId,
      fileTypeId: singleUser.fileTypeId,
      intakeId: singleUser.intakeId,
      fileModeId: singleUser.fileModeId,
      duration: singleUser.duration,
      address: singleUser.address,
      phoneNumber: singleUser.phoneNumber,
      lastPercentage: singleUser.lastPercentage,
      tenthPercentage: singleUser.tenthPercentage,
      twelfthPercentage: singleUser.twelfthPercentage,
      streamPercentage: singleUser.streamPercentage,
      best3Percentage: singleUser.best3Percentage,
      best3Details: singleUser.best3Details,
      passoutYear: singleUser.passoutYear,
      ieltsScore: singleUser.ieltsScore,
      listeningScore: singleUser.listeningScore,
      readingScore: singleUser.readingScore,
      writingScore: singleUser.writingScore,
      speakingScore: singleUser.speakingScore,
      specialRemark: singleUser.specialRemark,
      documentRemark: singleUser.documentRemark,
      counsellorId: singleUser.counsellorId,
      passportDoc: singleUser.passportDoc,
      tenthDoc: singleUser.tenthDoc,
      twelfthDoc: singleUser.twelfthDoc,
      graduationDoc: singleUser.graduationDoc,
      degreeDoc: singleUser.degreeDoc,
      ieltsPTEDoc: singleUser.ieltsPTEDoc,
      scoreValidUpTo: convertDateFormat(
        singleUser.ieltsScoreValidDate,
        "yyyy-mm-dd"
      ),
    });
    if (singleUser.stateId) {
      getDistricts(singleUser.stateId);
    }

    if (singleUser.visaCountryId) {
      getCity(singleUser.visaCountryId);
    }
  };

  const getAllPromises = () => {
    setBranchId(selector.branch.userBranchId);
    const getCountriesRequest = getAll("VisaCountry", payload);
    const VisaCity = getAll("VisaCountry", payload);
    const getVisaTypeRequest = getAll("VisaType", payload);
    const getCollegeRequest = getAll("VisaCollege", payload);
    const getCourseRequest = getAll("VisaCourse", payload);
    const getFileTypeRequest = getAll("FileType", payload);
    const getReferenceRequest = getAll("Reference", payload);
    const getStateRequest = getAll("State", payload);
    const getQualStreamRequest = getAll("QualStream", payload);
    const getBoardRequest = getAll("Board", payload);
    const getCounsellorRequest = getUsersByRole(
      ROLES.COUNSELLOR,
      selector.branch.userBranchId
    );
    const getFundsRequest = getAll("FundType", payload);
    const getGradeRequest = getAll("Grade", payload);
    const getBankRequest = getAll("Bank", payload);
    Promise.all([
      VisaCity,
      getCountriesRequest,
      getVisaTypeRequest,
      getCollegeRequest,
      getCourseRequest,
      getReferenceRequest,
      getStateRequest,
      getQualStreamRequest,
      getBoardRequest,
      getCounsellorRequest,
      getFundsRequest,
      getFileTypeRequest,
      getGradeRequest,
      getBankRequest,
    ])
      .then((res) => {
        const [
          city,
          countryResponse,
          visaTypesResponse,
          collegeResponse,
          courseResponse,
          referenceResponse,
          stateResponse,
          qualStreamResponse,
          boardResponse,
          counsellor,
          fundResponse,
          fileTypeResponse,
          grade,
          bankResponse,
        ] = res;
        const VisaCity = city.data.data.map((city) => ({
          text: city.name,
          value: city.visaCountryId,
        }));
        const Countries = countryResponse.data.data.map((countries) => ({
          text: countries.name,
          value: countries.visaCountryId,
        }));
        const VisaTypes = visaTypesResponse.data.data.map((visaType) => ({
          text: visaType.name,
          value: visaType.visaTypeId,
        }));
        const College = collegeResponse.data.data.map((college) => ({
          text: college.name,
          value: college.visaCollegeId,
        }));
        const Course = courseResponse.data.data.map((course) => ({
          text: course.name,
          value: course.visaCourseId,
        }));
        const State = stateResponse.data.data.map((state) => ({
          text: state.name,
          value: state.stateId,
        }));
        const QualStream = qualStreamResponse.data.data.map((qualStream) => ({
          text: qualStream.name,
          value: qualStream.qualStreamId,
        }));
        const Board = boardResponse.data.data.map((board) => ({
          text: board.name,
          value: board.boardId,
        }));
        const Reference = referenceResponse.data.data.map((reference) => ({
          text: reference.name,
          value: reference.referenceId,
        }));
        const Fund = fundResponse.data.data.map((fund) => ({
          text: fund.name,
          value: fund.fundTypeId,
        }));
        const FileType = fileTypeResponse.data?.data?.map((fileType) => ({
          text: fileType.name,
          value: fileType.fileTypeId,
        }));
        const counsellorResp = counsellor.data.map((counsellor) => ({
          text: counsellor.name,
          value: counsellor.userId,
        }));
        const Grade = grade.data.data.map((grade) => ({
          text: grade.name,
          value: grade.gradeId,
        }));
        const Bank = bankResponse.data.data.map((bank) => ({
          text: bank.name,
          value: bank.bankId,
        }));
        setCountries(Countries);
        setVisaTypes(VisaTypes);
        setCollege(College);
        setCourse(Course);
        setFileType(FileType);
        setReference(Reference);
        setState(State);
        setQualStream(QualStream);
        setBoard(Board);
        setFund(Fund);
        setCounsellorState(counsellorResp);
        setGrade(Grade);
        setBank(Bank);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };
  const getRecord = async () => {
    try {
      if (params.state.enquiryID) {
        let record = (await getById("Enquiry", params.state.enquiryID)).data;
        setSingleRecord(record);
        setInitialState({
          emailId: record.emailId,
          applicantName: record.name,
          boardId: record.boardId,
          visaCountryId: record.visaCountryId,
          visaCityId: record.visaCityId,
          visaTypeId: record.visaTypeId,
          passportNo: record.passportNumber,
          alternateNo: record.alternateNumber,
          //gradeId: record.gradeId,
          grade: record.grade,
          stateId: record.stateId,
          qualStreamId: record.qualStreamId,
          father: record.guardianName,
          date: record.createdOn,
          address: record.address,
          phoneNumber: record.phoneNumber,
          dob: record.dob,
          lastPercentage: record.lastPercentage,
          tenthPercentage: record.tenthPercentage,
          best3Percentage: record.best3Percentage,
          best3Details: record.best3Details,
          passoutYear: record.passoutYear,
          ieltsScore: record.ieltsScore,
          listeningScore: record.listeningScore,
          readingScore: record.readingScore,
          writingScore: record.writingScore,
          speakingScore: record.speakingScore,
          specialRemark: record.specialRemark,
          counsellorId: record.counsellorId,
          passportDoc: record.passportDoc ? record.passportDoc : 3,
          tenthDoc: record.tenthDoc ? record.tenthDoc : 3,
          twelfthDoc: record.twelfthDoc ? record.twelfthDoc : 3,
          graduationDoc: record.graduationDoc ? record.graduationDoc : 3,
          degreeDoc: record.degreeDoc ? record.degreeDoc : 3,
          ieltsPTEDoc: record.ieltsPTEDoc ? record.ieltsPTEDoc : 3,
          scoreValidUpTo: record.scoreValidUpTo,
          ...record,
        });
        if (record.visaCountryId) {
          getCity(record.visaCountryId);
        }
      }
    } catch (error) {}
  };
  const getAllRecords = () => {
    api
      .post("/VisaCity/getAll", apiPayload)
      .then((res) => {
        setTotalCount(res.data.data.total);
        const visaCityArray = res.data.data.data.map((visaCity, index) => ({
          Status: visaCity.isActive,
          Name: visaCity.name,
          ...visaCity,
        }));
        setTableRowData(visaCityArray);
      })
      .catch((err) => {});
  };
  const handleRadioChange = (e, name, setValue) => {
    setValue(name, e.target.value);
  };

  const getDistricts = (stateId) => {
    api
      .get(`District/getByState/${stateId}`)
      .then((res) => {
        const Districts = res.data.data.map((dist) => ({
          text: dist.name,
          value: dist.districtId,
        }));
        setDistrict(Districts);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const getCity = (countryId) => {
    api
      .get(`VisaCity/getByCountry/${countryId}`)
      .then((res) => {
        const City = res.data.data.map((city) => ({
          text: city.name,
          value: city.visaCityId,
        }));
        setCity([...City]);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  useEffect(() => {
    document.title = isEdit
      ? "Edit Immigration File | Digital Filing"
      : "Add Immigration File | Digital Filing";
    if (selector.branch.userBranchId) getAllPromises();
    if (isEdit || isView) getImmigrationFile();
    if (isEnquiry) getRecord();
  }, [
    payload,
    selector.branch.userBranchId,
    isEdit && params.state.enquiryID != undefined,
    isView,
    isEnquiry,
  ]);

  return (
    <Container maxW="container.xll" padding={"20px"} display={"block"}>
      <Box>
        <BreadcrumbBox
          route={
            isEdit
              ? GET_BREADCRUMB_ROUTES(MENU_NAMES.EDIT_IMMIGRATION)
              : GET_BREADCRUMB_ROUTES(MENU_NAMES.ADD_IMMIGRATION)
          }
        />
      </Box>
      <Formik
        validationSchema={IMMIGRATION_VALIDATION_SCHEMA}
        enableReinitialize={true}
        initialValues={initialState}
        onSubmit={(values) => {
          values.branchId = selector.branch.userBranchId;
          values.caseStatusId = !isEdit ? 1 : values.caseStatusId;
          values.enquiryId = params.state.enquiryID;
          let finalPayload = IMMIGRATION_PAYLOAD.CREATE(values, singleRecord);
          if (isEdit) {
            finalPayload.immigrationFileId = params.state.id;
            api
              .put(`/ImmigrationFile/replace`, { ...finalPayload })
              .then((res) => {
                SuccessAlert("Record updated successfully");
                navigate(ROUTE_LINKS[MENU_NAMES.IMMIGRATION]);
              })
              .catch((err) => {
                console.log(err, "err");
              });
            return;
          }
          api
            .post(`/ImmigrationFile/create`, { ...finalPayload })
            .then((res) => {
              SuccessAlert("Record added successfully");
              navigate(ROUTE_LINKS[MENU_NAMES.IMMIGRATION]);
            })
            .catch((err) => {
              console.log(err, "err");
            });
        }}
      >
        {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
          <Form
            noValidate={true}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Box
              color={colors.white}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              marginBottom={"20px"}
            >
              <Box>
                <Heading variant={"h1"}>
                  {isEdit
                    ? "Edit Immigration File"
                    : "Create New Immigration File"}
                </Heading>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                gap={"10px"}
                marginBottom={"20px"}
              >
                <Button
                  variant={"warning"}
                  onClick={() => {
                    navigate(ROUTE_LINKS[MENU_NAMES.IMMIGRATION]);
                  }}
                >
                  Discard
                </Button>
                <Button variant={"primary"} type="submit">
                  {isLoading ? (
                    <CircularProgress
                      isIndeterminate
                      size="24px"
                      color={colors.white}
                    />
                  ) : isEdit ? (
                    "Save"
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Box>
            </Box>
            <Box
              borderRadius={"10px"}
              display={"flex"}
              gap={"20px"}
              flexDirection={"column"}
            >
              <Box
                borderRadius={"10px"}
                border={"1px solid #e6e6e6"}
                padding={"8px 0px"}
              >
                <SimpleGrid
                  columns={{ base: "1", sm: "1", md: "4", lg: "4" }}
                  spacingX="30px"
                  spacingY="0px"
                  padding={"15px 15px 0px"}
                >
                  <SelectInput
                    formControl={{
                      id: "fileModeId",
                      variant: "floating",
                    }}
                    name="fileModeId"
                    isRequired={true}
                    isReadOnly={isLoading}
                    value={values.fileModeId}
                    label={{
                      text: "File Mode",
                      colors: errors.fileModeId ? "red !important" : "",
                      variant: errors.fileModeId ? "errored" : "",
                    }}
                    options={FILE_TYPE}
                    variant={errors.fileModeId ? "errored" : "flushed"}
                    onChange={handleChange}
                  />
                  <TextInput
                    type="text"
                    name="passportNo"
                    placeholder={""}
                    isRequired={true}
                    isReadOnly={isEdit || isView}
                    value={values.passportNo}
                    variant={errors.passportNo ? "errored" : "flushed"}
                    label={{
                      text: "Passport No",
                      colors: errors.passportNo ? "red !important" : "",
                      variant: errors.passportNo ? "errored" : "",
                    }}
                    onChange={handleChange}
                  />
                  <TextInput
                    type="text"
                    name="applicantName"
                    placeholder={""}
                    isRequired={true}
                    isReadOnly={isEdit || isView}
                    value={values.applicantName}
                    variant={errors.applicantName ? "errored" : "flushed"}
                    label={{
                      text: "Applicant Name",
                      colors: errors.applicantName ? "red !important" : "",
                      variant: errors.applicantName ? "errored" : "",
                    }}
                    onChange={handleChange}
                  />
                  <TextInput
                    type="text"
                    name="father"
                    placeholder={""}
                    isRequired={true}
                    isReadOnly={isEdit || isView}
                    value={values.father}
                    variant={errors.father ? "errored" : "flushed"}
                    label={{
                      text: "Father/Husband",
                      colors: errors.father ? "red !important" : "",
                      variant: errors.father ? "errored" : "",
                    }}
                    onChange={handleChange}
                  />
                  <TextInput
                    type="date"
                    name="dob"
                    isRequired={true}
                    placeholder={""}
                    value={convertDateFormat(values.dob, "yyyy-mm-dd")}
                    isDisabled={
                      !isEdit ||
                      selector.auth.login.RoleId == ROLES.ADMINISTRATOR
                        ? false
                        : true
                    }
                    onChange={handleChange}
                    label={{
                      text: "DOB",
                      colors: errors.dob ? "red !important" : "",
                      variant: errors.dob ? "errored" : "",
                    }}
                    variant={errors.dob ? "errored" : "flushed"}
                    // isReadOnly={isEdit || isView}
                    isReadOnly={true}
                  />

                  <TextInput
                    type="text"
                    name="address"
                    placeholder={""}
                    isRequired={true}
                    isReadOnly={isLoading}
                    value={values.address}
                    variant={errors.address ? "errored" : "flushed"}
                    label={{
                      text: "Address",
                      colors: errors.address ? "red !important" : "",
                      variant: errors.address ? "errored" : "",
                    }}
                    onChange={handleChange}
                  />

                  <TextInput
                    type="tel"
                    isRequired={true}
                    name="phoneNumber"
                    value={values.phoneNumber}
                    label={{
                      text: "Phone Number",
                      colors: errors.phoneNumber ? "red !important" : "",
                      variant: errors.phoneNumber ? "errored" : "",
                    }}
                    onChange={handleChange}
                    placeholder={""}
                    variant={errors.phoneNumber ? "errored" : "flushed"}
                    isReadOnly={true}
                  />
                  <TextInput
                    type="text"
                    name="alternateNo"
                    placeholder={""}
                    isRequired={true}
                    isReadOnly={isLoading}
                    value={values.alternateNo}
                    variant={errors.alternateNo ? "errored" : "flushed"}
                    label={{
                      text: "Alternative No",
                      colors: errors.alternateNo ? "red !important" : "",
                      variant: errors.alternateNo ? "errored" : "",
                    }}
                    onChange={handleChange}
                  />
                  <SelectInput
                    formControl={{
                      id: "stateId",
                      variant: "floating",
                    }}
                    name="stateId"
                    isRequired={true}
                    isReadOnly={isLoading}
                    value={values.stateId}
                    label={{
                      text: "State",
                      colors: errors.stateId ? "red !important" : "",
                      variant: errors.stateId ? "errored" : "",
                    }}
                    onChange={(e) => {
                      handleChange(e);
                      getDistricts(e.target.value);
                    }}
                    options={state}
                    variant={errors.stateId ? "errored" : "flushed"}
                  />

                  <SelectInput
                    formControl={{
                      id: "districtId",
                      variant: "floating",
                    }}
                    name={"districtId"}
                    isRequired={true}
                    isReadOnly={isLoading}
                    value={values.districtId}
                    label={{
                      text: "District",
                      colors: errors.districtId ? "red !important" : "",
                      variant: errors.districtId ? "errored" : "",
                    }}
                    onChange={handleChange}
                    variant={errors.districtId ? "errored" : "flushed"}
                    options={district}
                  />
                  <SelectInput
                    formControl={{
                      id: "visaTypeId",
                      variant: "floating",
                    }}
                    name="visaTypeId"
                    isRequired={true}
                    isReadOnly={isLoading}
                    value={values.visaTypeId}
                    label={{
                      text: "Visa Type",
                      colors: errors.visaTypeId ? "red !important" : "",
                      variant: errors.visaTypeId ? "errored" : "",
                    }}
                    options={visaTypes}
                    variant={errors.visaTypeId ? "errored" : "flushed"}
                    onChange={handleChange}
                  />
                  <SelectInput
                    formControl={{
                      id: "visaCountryId",
                      variant: "floating",
                    }}
                    name="visaCountryId"
                    isRequired={true}
                    isReadOnly={isLoading}
                    value={values.visaCountryId}
                    label={{
                      text: "Visa Country",
                      colors: errors.visaCountryId ? "red !important" : "",
                      variant: errors.visaCountryId ? "errored" : "",
                    }}
                    onChange={(e) => {
                      handleChange(e);
                      getCity(e.target.value);
                      setVisaCountryId(e.target.value);
                    }}
                    options={countries}
                    variant={errors.visaCountryId ? "errored" : "flushed"}
                  />
                  <Box display={"flex"} position={"relative"}>
                    <Box width={"100%"}>
                      <SelectInput
                        formControl={{
                          id: "visaCityId",
                          variant: "floating",
                        }}
                        name={"visaCityId"}
                        // isRequired={values.visaCountryId != null}
                        isRequired={false}
                        isReadOnly={isLoading}
                        value={values.visaCityId}
                        label={{
                          text: "Visa City",
                          colors: errors.visaCityId ? "red !important" : "",
                          variant: errors.visaCityId ? "errored" : "",
                        }}
                        onChange={handleChange}
                        variant={errors.visaCityId ? "errored" : "flushed"}
                        options={city}
                      />
                    </Box>
                    <Button
                      position={"absolute"}
                      right={"22px"}
                      // variant={"primary"}
                      color={colors.black}
                      borderRadius={"50%"}
                      width={"36px"}
                      height={"36px"}
                      fontSize={"20px"}
                      onClick={onOpen}
                    >
                      +
                    </Button>
                  </Box>
                  {/* <SelectInput
                    formControl={{
                      id: "gradeId",
                      variant: "floating",
                    }}
                    name="gradeId"
                    isRequired={true}
                    isReadOnly={isEdit || isView}
                    value={values.gradeId}
                    label={{
                      text: "Grade",
                      colors: errors.gradeId ? "red !important" : "",
                      variant: errors.gradeId ? "errored" : "",
                    }}
                    options={grade}
                    variant={errors.gradeId ? "errored" : "flushed"}
                    onChange={handleChange}
                  /> */}
                  <TextInput
                    type="text"
                    name="grade"
                    placeholder={""}
                    isRequired={true}
                    isReadOnly={isGradeReadonly}
                    value={values.grade}
                    variant={errors.grade ? "errored" : "flushed"}
                    label={{
                      text: "Grade",
                      colors: errors.grade ? "red !important" : "",
                      variant: errors.grade ? "errored" : "",
                    }}
                    onChange={handleChange}
                  />
                </SimpleGrid>
              </Box>
              <Box>
                <SimpleGrid
                  columns={{ base: "1", md: "3", lg: "3" }}
                  spacing={5}
                >
                  <Accordion defaultIndex={[0]} allowMultiple>
                    <AccordionItem
                      borderRadius={"10px"}
                      border={"1px solid #e6e6e6"}
                      marginTop={"0px"}
                      overflow={"hidden"}
                      height={"100%"}
                    >
                      <AccordionButton
                        backgroundColor={colors.gray.gray300}
                        color={colors.black}
                      >
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontWeight={"600"}
                        >
                          Other Detail
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel pb={4}>
                        <SimpleGrid
                          columns={{ base: "1", sm: "1", md: "1", lg: "1" }}
                          spacingX="30px"
                          spacingY="0px"
                          padding={"15px 0px"}
                          paddingBottom={"0px"}
                        >
                          <Box w={"100%"} display={"flex"} gap={"10px"}>
                            <Box w={"50%"}>
                              <TextInput
                                type="number"
                                name="ieltsScore"
                                placeholder={""}
                                isReadOnly={isLoading}
                                value={values.ieltsScore}
                                variant={
                                  errors.ieltsScore ? "errored" : "flushed"
                                }
                                label={{
                                  text: "Ielts Score",
                                  colors: errors.ieltsScore
                                    ? "red !important"
                                    : "",
                                  variant: errors.ieltsScore ? "errored" : "",
                                }}
                                onChange={handleChange}
                                width={"30px"}
                              />
                            </Box>
                            <Box w={"50%"}>
                              <Box
                                display={"flex"}
                                gap={"5px"}
                                justifyContent={"end"}
                              >
                                <TextInput
                                  type="number"
                                  name="listeningScore"
                                  alignItems={"center"}
                                  width={"30px"}
                                  placeholder={""}
                                  value={values.listeningScore}
                                  label={{
                                    text: "L",
                                    colors: errors.listeningScore
                                      ? "red !important"
                                      : "",
                                    variant: errors.listeningScore
                                      ? "errored"
                                      : "",
                                  }}
                                  onChange={handleChange}
                                  variant={
                                    errors.listeningScore
                                      ? "errored"
                                      : "flushed"
                                  }
                                  isReadOnly={isLoading}
                                />
                                <TextInput
                                  type="number"
                                  name="writingScore"
                                  alignItems={"center"}
                                  width={"30px"}
                                  placeholder={""}
                                  value={values.writingScore}
                                  label={{
                                    text: "W",
                                    colors: errors.writingScore
                                      ? "red !important"
                                      : "",
                                    variant: errors.writingScore
                                      ? "errored"
                                      : "",
                                  }}
                                  onChange={handleChange}
                                  variant={
                                    errors.writingScore ? "errored" : "flushed"
                                  }
                                  isReadOnly={isLoading}
                                />
                                <TextInput
                                  type="number"
                                  name="readingScore"
                                  alignItems={"center"}
                                  width={"30px"}
                                  placeholder={""}
                                  value={values.readingScore}
                                  label={{
                                    text: "R",
                                    colors: errors.readingScore
                                      ? "red !important"
                                      : "",
                                    variant: errors.readingScore
                                      ? "errored"
                                      : "",
                                  }}
                                  onChange={handleChange}
                                  variant={
                                    errors.readingScore ? "errored" : "flushed"
                                  }
                                  isReadOnly={isLoading}
                                />
                                <TextInput
                                  type="number"
                                  name="speakingScore"
                                  alignItems={"center"}
                                  width={"30px"}
                                  placeholder={""}
                                  value={values.speakingScore}
                                  label={{
                                    text: "S",
                                    colors: errors.speakingScore
                                      ? "red !important"
                                      : "",
                                    variant: errors.speakingScore
                                      ? "errored"
                                      : "",
                                  }}
                                  onChange={handleChange}
                                  variant={
                                    errors.speakingScore ? "errored" : "flushed"
                                  }
                                  isReadOnly={isLoading}
                                />
                              </Box>
                            </Box>
                          </Box>

                          <SimpleGrid
                            columns={{ base: "1", sm: "1", md: "2", lg: "2" }}
                            spacingX="30px"
                            spacingY="0px"
                            padding={"0px 0px"}
                            paddingBottom={"0px"}
                          >
                            <TextInput
                              type="text"
                              name="emailId"
                              value={values.emailId}
                              isDisabled={
                                !isEdit ||
                                selector.auth.login.RoleId ==
                                  ROLES.ADMINISTRATOR
                                  ? false
                                  : true
                              }
                              label={{
                                text: "Email",
                                colors: errors.emailId ? "red !important" : "",
                                variant: errors.emailId ? "errored" : "",
                              }}
                              onChange={handleChange}
                              placeholder={""}
                              variant={errors.emailId ? "errored" : "flushed"}
                              isReadOnly={isEdit || isView}
                            />
                            <TextInput
                              type="date"
                              name="scoreValidUpTo"
                              value={values.scoreValidUpTo}
                              label={{
                                text: "Score Valid Upto",
                                colors: errors.scoreValidUpTo
                                  ? "red !important"
                                  : "",
                                variant: errors.scoreValidUpTo ? "errored" : "",
                              }}
                              onChange={handleChange}
                              placeholder={""}
                              variant={
                                errors.scoreValidUpTo ? "errored" : "flushed"
                              }
                              isReadOnly={isLoading}
                            />
                          </SimpleGrid>
                          <SimpleGrid
                            columns={{ base: "1", sm: "1", md: "2", lg: "2" }}
                            spacingX="30px"
                            spacingY="0px"
                            padding={"0px 0px"}
                            paddingBottom={"0px"}
                          >
                            <SelectInput
                              formControl={{
                                id: "intakeId",
                                variant: "floating",
                              }}
                              isReadOnly={isLoading}
                              value={values.intakeId}
                              label={{
                                text: "Intake",
                                colors: errors.intakeId ? "red !important" : "",
                                variant: errors.intakeId ? "errored" : "",
                              }}
                              name="intakeId"
                              onChange={handleChange}
                              options={INTAKE}
                              variant={errors.intakeId ? "errored" : "flushed"}
                            />

                            <TextInput
                              type="text"
                              name="duration"
                              value={values.duration}
                              label={{
                                text: "Duration",
                                colors: errors.duration ? "red !important" : "",
                                variant: errors.duration ? "errored" : "",
                              }}
                              onChange={handleChange}
                              placeholder={""}
                              variant={errors.duration ? "errored" : "flushed"}
                              isReadOnly={isLoading}
                            />
                          </SimpleGrid>
                        </SimpleGrid>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                  <Accordion defaultIndex={[0]} allowMultiple>
                    <AccordionItem
                      borderRadius={"10px"}
                      border={"1px solid #e6e6e6"}
                      overflow={"hidden"}
                    >
                      <AccordionButton
                        backgroundColor={colors.gray.gray300}
                        color={colors.black}
                      >
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontWeight={"600"}
                        >
                          Qualification
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel pb={4}>
                        <SimpleGrid
                          columns={{ base: "1", sm: "1", md: "2", lg: "2" }}
                          spacingX="30px"
                          spacingY="0px"
                          padding={"15px 0px"}
                          paddingBottom={"0px"}
                        ></SimpleGrid>
                        <SimpleGrid
                          columns={{ base: "1", sm: "1", md: "2", lg: "2" }}
                          spacingX="30px"
                          spacingY="0px"
                          padding={"0px 0px"}
                          paddingBottom={"0px"}
                        >
                          <TextInput
                            type="text"
                            name="tenthPercentage"
                            isRequired={true}
                            value={values.tenthPercentage}
                            label={{
                              text: "10% Age",
                              colors: errors.tenthPercentage
                                ? "red !important"
                                : "",
                              variant: errors.tenthPercentage ? "errored" : "",
                            }}
                            onChange={handleChange}
                            placeholder={""}
                            variant={
                              errors.tenthPercentage ? "errored" : "flushed"
                            }
                            isReadOnly={isLoading}
                          />
                          <TextInput
                            type="text"
                            name="twelfthPercentage"
                            isRequired={true}
                            value={values.twelfthPercentage}
                            label={{
                              text: "12% Age",
                              colors: errors.twelfthPercentage
                                ? "red !important"
                                : "",
                              variant: errors.twelfthPercentage
                                ? "errored"
                                : "",
                            }}
                            onChange={handleChange}
                            placeholder={""}
                            variant={
                              errors.twelfthPercentage ? "errored" : "flushed"
                            }
                            isReadOnly={isLoading}
                          />
                        </SimpleGrid>
                        <SimpleGrid
                          columns={{ base: "1", sm: "1", md: "2", lg: "2" }}
                          spacingX="30px"
                          spacingY="0px"
                          padding={"0px 0px"}
                          paddingBottom={"0px"}
                        >
                          <SelectInput
                            formControl={{
                              id: "qualStreamId",
                              variant: "floating",
                            }}
                            name="qualStreamId"
                            isReadOnly={isLoading}
                            isRequired={true}
                            value={values.qualStreamId}
                            label={{
                              text: "Stream",
                              colors: errors.qualStreamId
                                ? "red !important"
                                : "",
                              variant: errors.qualStreamId ? "errored" : "",
                            }}
                            onChange={handleChange}
                            options={qualStream}
                            variant={
                              errors.qualStreamId ? "errored" : "flushed"
                            }
                          />

                          <SelectInput
                            formControl={{
                              id: "boardId",
                              variant: "floating",
                            }}
                            isReadOnly={isLoading}
                            isRequired={true}
                            value={values.boardId}
                            label={{
                              text: "Board",
                              colors: errors.boardId ? "red !important" : "",
                              variant: errors.boardId ? "errored" : "",
                            }}
                            name="boardId"
                            options={board}
                            onChange={handleChange}
                            variant={errors.boardId ? "errored" : "flushed"}
                          />
                          <TextInput
                            type="text"
                            name="Percentage"
                            isRequired={false}
                            value={values.Percentage}
                            label={{
                              text: "Percentage",
                              colors: errors.Percentage ? "red !important" : "",
                              variant: errors.Percentage ? "errored" : "",
                            }}
                            onChange={handleChange}
                            placeholder={""}
                            variant={errors.Percentage ? "errored" : "flushed"}
                            isReadOnly={isLoading}
                          />
                        </SimpleGrid>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                  <Accordion defaultIndex={[0]} allowMultiple>
                    <AccordionItem
                      borderRadius={"10px"}
                      border={"1px solid #e6e6e6"}
                      overflow={"hidden"}
                      height={"100%"}
                    >
                      <AccordionButton
                        backgroundColor={colors.gray.gray300}
                        color={colors.black}
                      >
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          fontWeight={"600"}
                        >
                          Office Detail
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel pb={4}>
                        <Box>
                          <SimpleGrid
                            columns={{ base: "1", sm: "1", md: "2", lg: "2" }}
                            spacingX="30px"
                            spacingY="0px"
                            padding={"15px 0px 0px"}
                          >
                            <SelectInput
                              formControl={{
                                id: "counsellorId",
                                variant: "floating",
                              }}
                              name="counsellorId"
                              isReadOnly={isLoading}
                              value={values.counsellorId}
                              label={{
                                text: "Counsellor",
                                colors: errors.counsellorId
                                  ? "red !important"
                                  : "",
                                variant: errors.counsellorId ? "errored" : "",
                              }}
                              options={counsellorState}
                              variant={
                                errors.counsellorId ? "errored" : "flushed"
                              }
                              onChange={handleChange}
                            />
                            <SelectInput
                              formControl={{
                                id: "fundTypeId",
                                variant: "floating",
                              }}
                              isReadOnly={isLoading}
                              value={values.fundTypeId}
                              label={{
                                text: "Fund Type",
                                colors: errors.fundTypeId
                                  ? "red !important"
                                  : "",
                                variant: errors.fundTypeId ? "errored" : "",
                              }}
                              name="fundTypeId"
                              options={fund}
                              onChange={handleChange}
                              variant={
                                errors.fundTypeId ? "errored" : "flushed"
                              }
                            />
                          </SimpleGrid>
                          <SimpleGrid
                            columns={{ base: "1", sm: "1", md: "2", lg: "2" }}
                            spacingX="30px"
                            spacingY="0px"
                            padding={"0px 0px"}
                            paddingBottom={"0px"}
                          >
                            <SelectInput
                              formControl={{
                                id: "courseId",
                                variant: "floating",
                              }}
                              isReadOnly={isLoading}
                              value={values.courseId}
                              label={{
                                text: "Course",
                                colors: errors.courseId ? "red !important" : "",
                                variant: errors.courseId ? "errored" : "",
                              }}
                              onChange={handleChange}
                              name="courseId"
                              options={course}
                              variant={errors.courseId ? "errored" : "flushed"}
                            />
                            <SelectInput
                              formControl={{
                                id: "referenceId",
                                variant: "floating",
                              }}
                              isReadOnly={isLoading}
                              value={values.referenceId}
                              label={{
                                text: "Reference",
                                colors: errors.referenceId
                                  ? "red !important"
                                  : "",
                                variant: errors.referenceId ? "errored" : "",
                              }}
                              name="referenceId"
                              options={reference}
                              onChange={handleChange}
                              variant={
                                errors.referenceId ? "errored" : "flushed"
                              }
                            />
                          </SimpleGrid>
                          <SimpleGrid
                            columns={{ base: "1", sm: "1", md: "2", lg: "2" }}
                            spacingX="30px"
                            spacingY="0px"
                            padding={"0px 0px"}
                            paddingBottom={"0px"}
                          >
                            <SelectInput
                              formControl={{
                                id: "visaCollegeId",
                                variant: "floating",
                              }}
                              isReadOnly={isLoading}
                              value={values.visaCollegeId}
                              label={{
                                text: "College",
                                colors: errors.visaCollegeId
                                  ? "red !important"
                                  : "",
                                variant: errors.visaCollegeId ? "errored" : "",
                              }}
                              name="visaCollegeId"
                              onChange={handleChange}
                              options={college}
                              variant={
                                errors.visaCollegeId ? "errored" : "flushed"
                              }
                            />
                            <SelectInput
                              formControl={{
                                id: "fileTypeId",
                                variant: "floating",
                              }}
                              isReadOnly={isLoading}
                              value={values.fileTypeId}
                              label={{
                                text: "File Type",
                                colors: errors.fileTypeId
                                  ? "red !important"
                                  : "",
                                variant: errors.fileTypeId ? "errored" : "",
                              }}
                              onChange={handleChange}
                              name="fileTypeId"
                              options={fileType}
                              variant={
                                errors.fileTypeId ? "errored" : "flushed"
                              }
                            />
                          </SimpleGrid>
                        </Box>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </SimpleGrid>
              </Box>

              <Accordion defaultIndex={[0]} allowMultiple>
                <AccordionItem
                  borderRadius={"10px"}
                  border={"1px solid #e6e6e6"}
                  overflow={"hidden"}
                  height={"100%"}
                >
                  <AccordionButton
                    backgroundColor={colors.gray.gray300}
                    color={colors.black}
                  >
                    <Box as="span" flex="1" textAlign="left" fontWeight={"600"}>
                      Remarks
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <Box marginBottom={"10px"}>
                      <Box
                        display={"grid"}
                        gap="15px"
                        gridTemplateColumns={{
                          base: "40% 29% 29%",
                          lg: "40% 29% 29%",
                        }}
                        alignItems={"flex-start"}
                      >
                        <Box
                          listStyleType={"none"}
                          borderRadius={"10px"}
                          gap={"20px"}
                          marginTop={"10px"}
                          padding={"15px 10px"}
                          border={"1px solid #e6e6e6"}
                          height={"100%"}
                        >
                          <Box
                            display={"flex"}
                            flexDirection="row"
                            justifyContent={"space-between"}
                            gap="10px"
                            padding={"15px 10px"}
                          >
                            <Box w="50%">
                              <Text></Text>
                            </Box>
                            <Box
                              w="60%"
                              display={"flex"}
                              flexDirection="row"
                              justifyContent={"space-between"}
                              gap="10px"
                              fontSize={"13px"}
                            >
                              <Text>Original</Text>
                              <Text>Duplicate</Text>
                              <Text>None</Text>
                            </Box>
                          </Box>
                          {[
                            { label: "Passport", key: "passportDoc" },
                            { label: "10th Certificate", key: "tenthDoc" },
                            { label: "10+2th Certificate", key: "twelfthDoc" },
                            { label: "Graduation", key: "graduationDoc" },
                            { label: "Degree", key: "degreeDoc" },
                            { label: "IELTS/PTE", key: "ieltsPTEDoc" },
                          ].map((item, index) => (
                            <Box
                              display={"flex"}
                              flexDirection="row"
                              gap="10px"
                              fontSize={"13px"}
                              padding={"5px 10px"}
                              key={index}
                            >
                              <Box w="40%">
                                <Text>{item.label}:</Text>
                              </Box>
                              <Box w="60%">
                                <RadioGroup defaultValue="1">
                                  <Stack
                                    spacing={10}
                                    direction="row"
                                    justifyContent={"space-around"}
                                  >
                                    <Radio
                                      value="1"
                                      onChange={(e) => {
                                        handleRadioChange(
                                          e,
                                          item.key,
                                          setFieldValue
                                        );
                                      }}
                                      isChecked={values[item.key]}
                                    />
                                    <Radio
                                      value="2"
                                      onChange={(e) => {
                                        handleRadioChange(
                                          e,
                                          item.key,
                                          setFieldValue
                                        );
                                      }}
                                      isChecked={values[item.key]}
                                    />
                                    <Radio
                                      value="3"
                                      onChange={(e) => {
                                        handleRadioChange(
                                          e,
                                          item.key,
                                          setFieldValue
                                        );
                                      }}
                                      isChecked={values[item.key]}
                                    />
                                  </Stack>
                                </RadioGroup>
                              </Box>
                            </Box>
                          ))}
                        </Box>

                        <Box
                          borderRadius={"10px"}
                          gap={"20px"}
                          marginTop={"10px"}
                          padding={"15px 10px"}
                          alignItems={"center"}
                          height={"100%"}
                          border={"1px solid #e6e6e6"}
                          className={"remarkTextarea"}
                        >
                          <TextArea
                            name="specialRemark"
                            minRows={"20"}
                            rows={20}
                            isReadOnly={isLoading}
                            value={values.specialRemark}
                            placeholder={""}
                            height={"unset"}
                            label={{
                              text: "Special Remarks",
                              colors: errors.specialRemark
                                ? "red !important"
                                : "",
                              variant: errors.specialRemark ? "errored" : "",
                            }}
                            onChange={handleChange}
                            variant={
                              errors.specialRemark ? "errored" : "flushed"
                            }
                          />
                        </Box>
                      </Box>
                    </Box>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Box>
          </Form>
        )}
      </Formik>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <Formik
            validationSchema={validationSchema}
            initialValues={initialState}
            enableReinitialize={true}
            onSubmit={(values) => {
              let finalPayload = {
                name: values.name,
                isActive: values.isActive,
                visaCountryId: values.visaCountryId,
              };
              api
                .post(`/VisaCity/create`, { ...finalPayload })
                .then((res) => {
                  SuccessAlert("Record Created successfully");
                  getCity(visacountryId);
                  onClose();
                  getAllRecords();
                })
                .catch((err) => {
                  console.log(err, "err");
                });
            }}
          >
            {({ handleSubmit, handleChange, values, errors }) => (
              <Form
                noValidate={true}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <ModalHeader borderBottom={"1px solid #EDEDED"}>
                  Create Visa City
                </ModalHeader>
                <ModalCloseButton
                  bg={colors.primaryColor}
                  color={colors.white}
                  position={"absolute"}
                  top={"15px"}
                />
                <ModalBody padding={"20px"}>
                  <SimpleGrid
                    columns={{ base: "1", sm: "1", md: "1", lg: "1" }}
                    spacingX="-3px"
                    spacingY="-6px"
                    gap={"10px"}
                  >
                    <TextInput
                      marginTop="20px"
                      formControl={{ id: "name" }}
                      type="text"
                      name="name"
                      isRequired={true}
                      isReadOnly={isView}
                      placeholder={""}
                      value={values.name}
                      label={{
                        text: "Name",
                        colors: errors.name ? "red !important" : "",
                        variant: errors.name ? "errored" : "",
                      }}
                      onChange={(e) => handleChange(e)}
                      variant={errors.name ? "errored" : "flushed"}
                    />
                    <SelectInput
                      formControl={{
                        id: "visaCountryId",
                        variant: "floating",
                      }}
                      isRequired={true}
                      isReadOnly={isView}
                      value={values.visaCountryId}
                      name="visaCountryId"
                      onChange={handleChange}
                      label={{
                        text: "Visa Country",
                      }}
                      options={countries}
                    />
                    <SwitchInput
                      placeholder={""}
                      isRequired={true}
                      isReadOnly={isView}
                      value={values.isActive}
                      name="isActive"
                      variant={errors.isActive ? "errored" : "flushed"}
                      label={{
                        text: "Active",
                        colors: errors.isActive ? "red !important" : "",
                        variant: errors.isActive ? "errored" : "",
                        fontSize: "12px",
                      }}
                      formControl={{ id: "isActive" }}
                      onChange={handleChange}
                    />
                  </SimpleGrid>
                </ModalBody>
                <ModalFooter
                  justifyContent={"space-between"}
                  borderTop={"1px solid #EDEDED "}
                >
                  <Button variant={"secondaryOutline"} onClick={onClose}>
                    {isView ? "Back" : "Discard"}
                  </Button>
                  <Button
                    variant={"primary"}
                    color={colors.white}
                    type="submit"
                  >
                    {isLoading ? (
                      <CircularProgress
                        isIndeterminate
                        size="24px"
                        color={colors.white}
                      />
                    ) : isEdit ? (
                      "Save"
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default AddImmigration;
