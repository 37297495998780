import {
  Badge,
  Box,
  Button,
  Checkbox,
  Heading,
  ListIcon,
  SimpleGrid,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  List,
  UnorderedList,
  ListItem,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { Container } from "chakra-paginator";
import { useLocation } from "react-router-dom";
import React from "react";
import TextInput from "../../theme/components/input/text-input";
import { colors } from "../../theme/styles/colors";
import BreadcrumbBox from "../../theme/components/breadcrumb";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { GET_BREADCRUMB_ROUTES } from "../../core/utils/navbar-items";
import { MENU_NAMES } from "../../core/utils/constants";
import moment from "moment";
import {
  CREATE_RESPONSE,
  convertDateFormat,
  getAll,
  getById,
} from "../../core/utils/common-function";
import { ROUTE_LINKS } from "../../core/utils/route-links";
import { GRID_COLUMNS } from "../../core/utils/grid-columns";
import Grid from "../../theme/components/grid/grid";
import SelectInput from "../../theme/components/input/select-input";

const ViewImmigration = (props) => {
  const navigate = useNavigate();
  const [activeCheckbox, setActiveCheckbox] = useState([]);
  const params = useLocation();
  const [pendingDocuments, setPendingDocuments] = useState([]);
  const [fileDocuments, setFileDocuments] = useState([]);
  const [fileDoc, setFileDoc] = useState([]);
  const [allRecords, setAllRecords] = useState([]);
  const [remarksTableData, setRemarksData] = useState([]);
  const [documentStatus, setDocumentStatus] = useState([]);
  const [viewModel, setViewModel] = useState({
    "Applicant Name": " ",
    "User Id": " ",
    "Alternative No": "",
    Country: "",
    "Husband/Father": "",
    dob: "",
    Rating: "",
    "Email Id": " ",
    "Phone Number": " ",
    "State ID": " ",
    "District ID": " ",
    Address: " ",
    "Profile ID": " ",
    "IELTS Score": "",
    Duration: "",
    Intake: "",
    "Visa Type": "",
    Qualification: "",
    "Last Percentage": "",
    "Passout Year": "",
    Board: "",
    Councellor: "",
    "File Incharge": "",
    Funds: "",
    "File Type": "",
    College: "",
    Course: "",
    "Reference Type": "",
    Grade: "",
    board: "",
    qualStream: "",
    immigrationFileRemark: [],
    fileDocumentStatus: [],
  });

  const getDocumentName = (docValue) => {
    let docName = "None";

    if (docValue === 1) {
      docName = "Original";
    }

    if (docValue === 2) {
      docName = "Duplicate";
    }

    return docName;
  };
  const [payload, setPayload] = useState({
    filter: [],
    pageSize: 0,
    pageIndex: 0,
  });
  const getSingleImmigrationFile = async () => {
    const singleFile = (await getById("ImmigrationFile", params?.state?.id))
      .data;
    setAllRecords(singleFile.fileDocumentStatus);
    if (singleFile.fileDocumentStatus.length >= 0) {
      let checkedDocs = singleFile.fileDocumentStatus.map((item) => {
        return item.fileDocumentId;
      });
      setFileDocuments(checkedDocs);
    }
    setViewModel({
      ...singleFile,
    });
    let remarksData = [];
    if (singleFile.immigrationFileRemark) {
      remarksData = CREATE_RESPONSE(
        GRID_COLUMNS.REMARK_LOGS,
        singleFile.immigrationFileRemark
      );
    }
    // let documentData = [];
    // if (singleFile.fileDocumentStatus) {
    //   documentData = CREATE_RESPONSE(columns, singleFile.fileDocumentStatus);
    // }
    setDocumentStatus(
      singleFile.fileDocumentStatus
        ? CREATE_RESPONSE(GRID_COLUMNS.FILE_LOGS, singleFile.fileDocumentStatus)
        : []
    );
    setRemarksData(remarksData);
  };

  const getAllPromises = () => {
    const FileDocument1 = getAll("FileDocument", payload);
    Promise.all([FileDocument1])
      .then((res) => {
        const [fileDocument1] = res;
        const FileDocument = fileDocument1.data.data.map((fileDocument1) => ({
          text: fileDocument1.name,
          value: fileDocument1.fileDocumentId,
        }));
        setFileDoc(FileDocument);
        setPendingDocuments(fileDocument1.data.data);
        getSingleImmigrationFile();
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };
  useEffect(() => {
    getAllPromises();
    // getDocument();
    // getFileDocumentName();
    document.title = "View Immigtation | Digital Filing";
  }, []);

  const getNameAndDate = (currentDocId) => {
    try {
      return allRecords.filter((item) =>
        item.fileDocumentId.includes(currentDocId)
      );
    } catch (error) {
      console.log(error, "error");
    }
  };

  // const getDocument = (documentName) => {
  //   return documentName.filter(
  //     (fileDocumentId) => fileDocumentId.colorId === documentName
  //   );
  // };

  return (
    <Container maxW="container.xll" padding={"20px"} display={"block"}>
      <Box>
        <BreadcrumbBox
          route={GET_BREADCRUMB_ROUTES(MENU_NAMES.VIEW_IMMIGRATION)}
        />
      </Box>
      <Box
        color={colors.white}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        marginBottom={"20px"}
      >
        <Box>
          <Heading variant={"h1"}>View Immigration</Heading>
        </Box>
        <Button
          fontSize={{ base: "12px", sm: "13px", md: "14px", lg: "14px" }}
          variant={"primary"}
          onClick={() => {
            navigate(ROUTE_LINKS[MENU_NAMES.EDIT_IMMIGRATION], {
              state: {
                id: params.state.id,
                enquiryID: params.state.enquiryID,
                isEdit: true,
              },
            });
          }}
        >
          Edit
        </Button>
      </Box>
      <Box
        borderRadius={"10px"}
        display={"flex"}
        gap={"20px"}
        flexDirection={"column"}
      >
        <Box
          borderRadius={"10px"}
          border={"1px solid #e6e6e6"}
          padding={"8px 0px"}
        >
          <SimpleGrid
            columns={{ base: "1", sm: "1", md: "4", lg: "4" }}
            spacingX="30px"
            spacingY="0px"
            padding={"15px 15px 0px"}
          >
            <TextInput
              type="text"
              formControl={{ id: "Passport No. " }}
              placeholder={""}
              value={viewModel.passportNumber}
              variant="flushed"
              label={{ text: "Passport No." }}
            />
            <TextInput
              type="text"
              variant="flushed"
              placeholder={""}
              value={viewModel.name}
              label={{ text: "Applicant Name" }}
            />
            <TextInput
              type="text"
              placeholder={""}
              variant="flushed"
              value={viewModel.guardianName}
              label={{ text: "Father/Husband" }}
            />
            <TextInput
              type="date"
              placeholder={""}
              variant="flushed"
              value={convertDateFormat(viewModel.dob, "yyyy-mm-dd")}
              label={{ text: "DOB" }}
            />
            <TextInput
              type="text"
              placeholder={""}
              variant="flushed"
              value={viewModel.address}
              label={{ text: "Address" }}
            />
            <TextInput
              type="text"
              placeholder={""}
              variant="flushed"
              value={viewModel.stateName}
              label={{ text: "State" }}
            />
            <TextInput
              type="text"
              placeholder={""}
              variant="flushed"
              value={viewModel.districtName}
              label={{ text: "District" }}
            />
            {/* <TextInput
              type="text"
              placeholder={""}
              variant="flushed"
              value={viewModel.branchName}
              label={{ text: "Branch" }}
            /> */}
            {/* <SelectInput
              formControl={{
                id: "districtId",
                variant: "floating",
              }}
              value={viewModel.districtName}
              label={{
                text: "District",
              }}
              name="districtId"
            />
            <SelectInput
              formControl={{
                id: "stateId",
                variant: "floating",
              }}
              value={viewModel.stateName}
              label={{
                text: "State",
              }}
              name="stateId"
            />
            <SelectInput
              formControl={{
                id: "branchId",
                variant: "floating",
              }}
              value={viewModel.branchName}
              label={{
                text: "Branch",
              }}
              // options={branchId}
              name="branchId"
            /> */}
            <TextInput
              type="text"
              placeholder={""}
              variant="flushed"
              value={viewModel.phoneNumber}
              label={{ text: "Phone No" }}
            />
            <TextInput
              type="text"
              placeholder={""}
              variant="flushed"
              value={viewModel.alternateNumber}
              label={{ text: "Alternative No" }}
            />
            <TextInput
              type="text"
              placeholder={""}
              variant="flushed"
              value={viewModel.visaCountryName}
              label={{ text: "Visa Country" }}
            />
            <TextInput
              type="text"
              placeholder={""}
              variant="flushed"
              value={viewModel.grade}
              label={{ text: "Grade" }}
            />
          </SimpleGrid>
        </Box>
        <SimpleGrid columns={{ base: "1", md: "3", lg: "3" }} spacing={5}>
          <Accordion defaultIndex={[0]} allowMultiple>
            <AccordionItem
              borderRadius={"10px"}
              border={"1px solid #e6e6e6"}
              marginTop={"0px"}
              overflow={"hidden"}
              height={"100%"}
            >
              <AccordionButton
                backgroundColor={colors.gray.gray300}
                color={colors.black}
              >
                <Box as="span" flex="1" textAlign="left" fontWeight={"600"}>
                  General Detail
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <SimpleGrid
                  columns={{ base: "1", sm: "1", md: "1", lg: "2" }}
                  spacingX="30px"
                  spacingY="0px"
                  padding={"15px 0px"}
                  paddingBottom={"0px"}
                >
                  <Box display={"flex"} gap={"10px"}>
                    <TextInput
                      type="number"
                      name="ielts"
                      placeholder={""}
                      value={viewModel.ieltsScore}
                      // variant={errors.ielts ? "errored" : "flushed"}
                      label={{
                        text: "Ielts Score",
                      }}
                    />
                  </Box>
                  <Box>
                    <Box display={"flex"} gap={"5px"} justifyContent={"end"}>
                      <TextInput
                        type="number"
                        name="listening"
                        variant="flushed"
                        alignItems={"center"}
                        width={"30px"}
                        placeholder={""}
                        value={viewModel.listeningScore}
                        label={{
                          text: "L",
                        }}
                      />
                      <TextInput
                        type="number"
                        name="writing"
                        alignItems={"center"}
                        width={"30px"}
                        placeholder={""}
                        value={viewModel.writingScore}
                        label={{
                          text: "W",
                        }}
                        variant="flushed"
                      />
                      <TextInput
                        type="number"
                        name="reading"
                        alignItems={"center"}
                        width={"30px"}
                        placeholder={""}
                        value={viewModel.readingScore}
                        label={{
                          text: "R",
                        }}
                        variant="flushed"
                      />
                      <TextInput
                        type="number"
                        name="speaking"
                        alignItems={"center"}
                        width={"30px"}
                        placeholder={""}
                        value={viewModel.speakingScore}
                        label={{
                          text: "S",
                        }}
                        variant="flushed"
                      />
                    </Box>
                  </Box>

                  <TextInput
                    type="text"
                    formControl={{ id: "Email Id " }}
                    placeholder={""}
                    value={viewModel.emailId}
                    variant="flushed"
                    label={{ text: "Email Id" }}
                  />
                  <TextInput
                    type="text"
                    placeholder={""}
                    variant="flushed"
                    value={viewModel.duration}
                    label={{ text: "Duration" }}
                  />
                  <TextInput
                    type="text"
                    placeholder={""}
                    variant="flushed"
                    value={viewModel.intakeName}
                    label={{ text: "Intake" }}
                  />
                  <TextInput
                    type="text"
                    placeholder={""}
                    variant="flushed"
                    value={viewModel.visaTypeName}
                    label={{ text: "Visa Type" }}
                  />
                </SimpleGrid>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <Accordion defaultIndex={[0]} allowMultiple>
            <AccordionItem
              borderRadius={"10px"}
              border={"1px solid #e6e6e6"}
              overflow={"hidden"}
              height={"100%"}
            >
              <AccordionButton
                backgroundColor={colors.gray.gray300}
                color={colors.black}
              >
                <Box as="span" flex="1" textAlign="left" fontWeight={"600"}>
                  Education Detail
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <SimpleGrid
                  columns={{ base: "1", sm: "1", md: "1", lg: "2" }}
                  spacingX="30px"
                  spacingY="0px"
                  padding={"15px 0px"}
                  paddingBottom={"0px"}
                >
                  <TextInput
                    type="text"
                    placeholder={""}
                    variant="flushed"
                    value={viewModel.tenthPercentage}
                    label={{ text: "10%Age" }}
                  />
                  <TextInput
                    type="text"
                    placeholder={""}
                    variant="flushed"
                    value={viewModel.twelfthPercentage}
                    label={{ text: "12%Age" }}
                  />
                  <TextInput
                    type="text"
                    placeholder={""}
                    variant="flushed"
                    value={viewModel.qualStreamName}
                    label={{
                      text: "Stream",
                    }}
                  />

                  <TextInput
                    type="text"
                    placeholder={""}
                    variant="flushed"
                    value={viewModel.boardName}
                    label={{
                      text: "Board",
                    }}
                    name="board"
                  />
                  <TextInput
                    type="text"
                    name="Percentage"
                    value={viewModel.percentage}
                    label={{
                      text: "Percentage",
                    }}
                    placeholder={""}
                  />
                </SimpleGrid>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <Accordion defaultIndex={[0]} allowMultiple>
            <AccordionItem
              borderRadius={"10px"}
              border={"1px solid #e6e6e6"}
              overflow={"hidden"}
              height={"100%"}
            >
              <AccordionButton
                backgroundColor={colors.gray.gray300}
                color={colors.black}
              >
                <Box as="span" flex="1" textAlign="left" fontWeight={"600"}>
                  Office Detail
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Box>
                  <SimpleGrid
                    columns={{ base: "1", sm: "1", md: "1", lg: "2" }}
                    spacingX="30px"
                    spacingY="0px"
                    paddingBottom={"0px"}
                    padding={"15px 0px 0px"}
                  >
                    <TextInput
                      type="text"
                      placeholder={""}
                      variant="flushed"
                      value={viewModel.counsellorName}
                      label={{ text: "Counsellor" }}
                    />
                    <TextInput
                      type="text"
                      placeholder={""}
                      variant="flushed"
                      value={viewModel.fundTypeName}
                      label={{ text: "Fund" }}
                    />
                    <TextInput
                      type="text"
                      placeholder={""}
                      variant="flushed"
                      value={viewModel.fileTypeName}
                      label={{ text: "File Type" }}
                    />
                    <TextInput
                      type="text"
                      placeholder={""}
                      variant="flushed"
                      value={viewModel.visaCollegeName}
                      label={{ text: "College" }}
                    />
                    <TextInput
                      type="text"
                      placeholder={""}
                      variant="flushed"
                      value={viewModel.visaCourseName}
                      label={{ text: "Course" }}
                    />
                    <TextInput
                      type="text"
                      placeholder={""}
                      variant="flushed"
                      value={viewModel.referenceName}
                      label={{ text: "Reference Type" }}
                    />
                  </SimpleGrid>
                </Box>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </SimpleGrid>
        <Accordion defaultIndex={[0]} allowMultiple>
          <AccordionItem
            borderRadius={"10px"}
            border={"1px solid #e6e6e6"}
            overflow={"hidden"}
          >
            <AccordionButton
              backgroundColor={colors.gray.gray300}
              color={colors.black}
            >
              <Box as="span" flex="1" textAlign="left" fontWeight={"600"}>
                Document
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Box>
                <SimpleGrid
                  columns={{ base: "1", md: "2", lg: "2" }}
                  spacing={5}
                >
                  <Box
                    listStyleType={"none"}
                    // backgroundColor={colors.white}
                    // boxShadow={"0px 0px 35px 0px #F5F5F5"}
                    borderRadius={"10px"}
                    gap={"20px"}
                    marginTop={"10px"}
                    padding={"15px 10px"}
                    border={"1px solid #e6e6e6"}
                  >
                    <UnorderedList marginLeft={0}>
                      <ListItem
                        listStyleType={"none"}
                        display={"flex"}
                        height={"28px"}
                        justifyContent={"space-between"}
                      >
                        <Box
                          fontSize={"13px"}
                          w={"50%"}
                          display={"flex"}
                          alignItems={"center"}
                        >
                          Passport
                        </Box>
                        <Box
                          display={"flex"}
                          fontSize={"12px"}
                          alignItems={"center"}
                        >
                          {getDocumentName(viewModel.passportDoc)}
                        </Box>
                      </ListItem>
                      <ListItem
                        listStyleType={"none"}
                        display={"flex"}
                        height={"28px"}
                        justifyContent={"space-between"}
                      >
                        <Box
                          fontSize={"13px"}
                          w={"50%"}
                          display={"flex"}
                          alignItems={"center"}
                        >
                          10th certificate
                        </Box>
                        <Box
                          fontSize={"12px"}
                          display={"flex"}
                          alignItems={"center"}
                        >
                          {getDocumentName(viewModel.tenthDoc)}
                        </Box>
                      </ListItem>
                      <ListItem
                        listStyleType={"none"}
                        display={"flex"}
                        height={"28px"}
                        justifyContent={"space-between"}
                      >
                        <Box
                          fontSize={"13px"}
                          w={"50%"}
                          display={"flex"}
                          alignItems={"center"}
                        >
                          10+2th certificate
                        </Box>
                        <Box
                          fontSize={"12px"}
                          display={"flex"}
                          alignItems={"center"}
                        >
                          {getDocumentName(viewModel.twelfthDoc)}
                        </Box>
                      </ListItem>
                      <ListItem
                        listStyleType={"none"}
                        display={"flex"}
                        height={"28px"}
                        justifyContent={"space-between"}
                      >
                        <Box
                          fontSize={"13px"}
                          w={"50%"}
                          display={"flex"}
                          alignItems={"center"}
                        >
                          Graduation
                        </Box>
                        <Box
                          fontSize={"12px"}
                          display={"flex"}
                          alignItems={"center"}
                        >
                          {getDocumentName(viewModel.graduationDoc)}
                        </Box>
                      </ListItem>
                      <ListItem
                        listStyleType={"none"}
                        display={"flex"}
                        height={"28px"}
                        justifyContent={"space-between"}
                      >
                        <Box
                          fontSize={"13px"}
                          w={"50%"}
                          display={"flex"}
                          alignItems={"center"}
                        >
                          Degree
                        </Box>
                        <Box
                          fontSize={"12px"}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          {getDocumentName(viewModel.degreeDoc)}
                        </Box>
                      </ListItem>
                      <ListItem
                        listStyleType={"none"}
                        display={"flex"}
                        height={"28px"}
                        justifyContent={"space-between"}
                      >
                        <Box
                          fontSize={"13px"}
                          w={"50%"}
                          display={"flex"}
                          alignItems={"center"}
                        >
                          IELTS/PTE/OET
                        </Box>
                        <Box
                          fontSize={"12px"}
                          display={"flex"}
                          alignItems={"center"}
                        >
                          {getDocumentName(viewModel.ieltsPTEDoc)}
                        </Box>
                      </ListItem>
                      <ListItem
                        listStyleType={"none"}
                        display={"flex"}
                        height={"28px"}
                        justifyContent={"space-between"}
                      >
                        <Box
                          fontSize={"13px"}
                          w={"50%"}
                          display={"flex"}
                          alignItems={"center"}
                        >
                          Other Documents
                        </Box>
                        <Box
                          fontSize={"12px"}
                          display={"flex"}
                          alignItems={"center"}
                        >
                          None
                        </Box>
                      </ListItem>
                    </UnorderedList>
                  </Box>
                  <Box
                    borderRadius={"10px"}
                    marginTop={"10px"}
                    padding={"17px 15px"}
                    border={"1px solid #e6e6e6"}
                  >
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Heading size={"sm"} variant={"h4"} margin={"0px"}>
                        History
                      </Heading>{" "}
                      <Box>
                        <Badge
                          backgroundColor="#a5658c"
                          color={colors.white}
                          onClick={() => {}}
                        >
                          Account
                        </Badge>
                      </Box>
                    </Box>
                    <UnorderedList marginLeft={0}>
                      <ListItem
                        listStyleType={"none"}
                        display={"flex"}
                        height={"28px"}
                        justifyContent={"space-between"}
                      >
                        <Box
                          fontSize={"13px"}
                          w={"50%"}
                          display={"flex"}
                          alignItems={"center"}
                        >
                          Grade:
                        </Box>
                        <Box
                          color={"#323232"}
                          fontSize={"12px"}
                          fontWeight={"700"}
                          display={"flex"}
                          alignItems={"center"}
                        >
                          {viewModel.grade}
                        </Box>
                      </ListItem>
                      <ListItem
                        listStyleType={"none"}
                        display={"flex"}
                        height={"28px"}
                        justifyContent={"space-between"}
                      >
                        <Box
                          fontSize={"13px"}
                          w={"50%"}
                          display={"flex"}
                          alignItems={"center"}
                        >
                          Old Grade
                        </Box>
                        <Box
                          display={"flex"}
                          color={"#323232"}
                          fontWeight={"700"}
                          fontSize={"13px"}
                          alignItems={"center"}
                        >
                          {viewModel.oldGrade}
                        </Box>
                      </ListItem>
                      <ListItem
                        listStyleType={"none"}
                        display={"flex"}
                        height={"28px"}
                        justifyContent={"space-between"}
                      >
                        <Box
                          fontSize={"13px"}
                          w={"50%"}
                          display={"flex"}
                          alignItems={"center"}
                        >
                          Final Grade:
                        </Box>
                        <Box
                          display={"flex"}
                          color={"#323232"}
                          fontWeight={"700"}
                          fontSize={"13px"}
                          alignItems={"center"}
                        >
                          {viewModel.finalGrade}
                        </Box>
                      </ListItem>
                      <ListItem
                        listStyleType={"none"}
                        display={"flex"}
                        height={"28px"}
                        justifyContent={"space-between"}
                      >
                        <Box
                          fontSize={"13px"}
                          w={"50%"}
                          display={"flex"}
                          alignItems={"center"}
                        >
                          Special Remark:
                        </Box>
                        <Box
                          display={"flex"}
                          color={"#323232"}
                          fontSize={"12px"}
                          alignItems={"center"}
                        >
                          {viewModel.specialRemark}
                        </Box>
                      </ListItem>
                    </UnorderedList>
                  </Box>
                </SimpleGrid>
              </Box>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Accordion defaultIndex={[0]} allowMultiple>
          <AccordionItem
            borderRadius={"10px"}
            border={"1px solid #e6e6e6"}
            overflow={"hidden"}
          >
            <AccordionButton
              backgroundColor={colors.gray.gray300}
              color={colors.black}
            >
              <Box as="span" flex="1" textAlign="left" fontWeight={"600"}>
                <Box display={"flex"} gap={"10px"} alignItems={"center"}>
                  File Status
                  <Badge
                    color={"#28a745"}
                    backgroundColor={"transparent"}
                    borderRadius={"30px"}
                    padding={"2px 10px"}
                    fontWeight={300}
                    border={"1px solid #28a745"}
                  >
                    Active
                  </Badge>
                </Box>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Box marginTop={"0px"} padding={"10px 0px"}>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                ></Box>
                <Box>
                  {
                    <List
                      display={"flex"}
                      gap={"15px"}
                      flexWrap={"wrap"}
                      colcount={4}
                      fontSize={"13px"}
                    >
                      {pendingDocuments &&
                        pendingDocuments.map((doc, index) => (
                          <Box
                            display={"flex"}
                            whiteSpace={"nowrap"}
                            width={{
                              base: "100%",
                              md: "47%",
                              lg: "49%",
                              xl: "32%",
                            }}
                            alignItems={"center"}
                          >
                            <ListIcon
                              as={Checkbox}
                              color="green.500"
                              isChecked={
                                fileDocuments.includes(doc.fileDocumentId)
                                  ? true
                                  : false
                              }
                            />
                            {doc.name}
                            {getNameAndDate(doc.fileDocumentId)?.[0]
                              ?.previousDocumentDate && (
                              <Badge
                                ml="1"
                                color={colors.black}
                                bg={colors.gray.gray200}
                              >
                                PD:{" "}
                                {convertDateFormat(
                                  getNameAndDate(doc.fileDocumentId)?.[0]
                                    ?.previousDocumentDate,
                                  "yyyy-mm-dd"
                                )}
                              </Badge>
                            )}
                            {getNameAndDate(doc.fileDocumentId)?.[0]
                              ?.createdOn && (
                              <Badge
                                ml="1"
                                color={colors.black}
                                bg={colors.gray.gray200}
                              >
                                {convertDateFormat(
                                  getNameAndDate(doc.fileDocumentId)?.[0]
                                    ?.createdOn,
                                  "yyyy-mm-dd"
                                )}
                              </Badge>
                            )}
                            {getNameAndDate(doc.fileDocumentId)?.[0]
                              ?.userName && (
                              <Badge
                                ml="1"
                                bg={"transparent"}
                                borderColor={colors.blue.blue300}
                                color={colors.blue.blue300}
                                borderRadius={"30px"}
                                padding={"0px 10px"}
                                fontWeight={300}
                                border={"1px solid"}
                              >
                                {
                                  getNameAndDate(doc.fileDocumentId)?.[0]
                                    ?.userName
                                }
                              </Badge>
                            )}
                          </Box>
                        ))}
                    </List>
                  }
                </Box>
              </Box>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Accordion defaultIndex={[0]} allowMultiple>
          <AccordionItem
            borderRadius={"10px"}
            border={"1px solid #e6e6e6"}
            overflow={"hidden"}
          >
            <AccordionButton
              backgroundColor={colors.gray.gray300}
              color={colors.black}
            >
              <Box as="span" flex="1" textAlign="left" fontWeight={"600"}>
                Remark History
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Box borderRadius={"10px"} marginTop={"0px"} padding={"0px"}>
                <Grid
                  name={"ViewImmigration"}
                  columns={GRID_COLUMNS.REMARK_LOGS}
                  data={remarksTableData}
                  hasAction={false}
                  hasColumnDrop={false}
                  permission={props}
                />
              </Box>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Accordion defaultIndex={[0]} allowMultiple>
          <AccordionItem
            borderRadius={"10px"}
            border={"1px solid #e6e6e6"}
            overflow={"hidden"}
          >
            <AccordionButton
              backgroundColor={colors.gray.gray300}
              color={colors.black}
            >
              <Box as="span" flex="1" textAlign="left" fontWeight={"600"}>
                File Logs
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Box borderRadius={"10px"} marginTop={"0px"} padding={"0px"}>
                <Grid
                  name={"document_status"}
                  columns={GRID_COLUMNS.FILE_LOGS}
                  data={documentStatus}
                  hasAction={false}
                  hasColumnDrop={false}
                  permission={props}
                />
              </Box>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>
    </Container>
  );
};

export default ViewImmigration;
