import React, { useEffect } from "react";
import { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  CloseButton,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Image,
  SimpleGrid,
  Switch,
} from "@chakra-ui/react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import BreadcrumbBox from "../../theme/components/breadcrumb";
import TextInput from "../../theme/components/input/text-input";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { colors } from "../../theme/styles/colors";
import { ERROR_CODES, MENU_NAMES } from "../../core/utils/constants";
import { GET_BREADCRUMB_ROUTES } from "../../core/utils/navbar-items";
import SelectInput from "../../theme/components/input/select-input";
import {
  convertDateFormat,
  getAll,
  getById,
} from "../../core/utils/common-function";
import api from "../../core/api";
import { ROUTE_LINKS } from "../../core/utils/route-links";
import {
  SuccessAlert,
  WarningAlert,
} from "../../core/utils/common-sweet-alert";
import SwitchInput from "../../theme/components/input/switch-input";
import SCHEMA_VALIDATOR from "../../core/utils/schema-validator";
import { useSelector } from "react-redux";

const AddUser = (props) => {
  const navigate = useNavigate();
  const selector = useSelector((_) => _);
  const [district, setDistrict] = useState([]);
  const [state, setState] = useState([]);
  const params = useLocation();
  const isEdit = params.state.isEdit;
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [initialState, setInitialState] = useState({
    name: "",
    emailId: "",
    phoneNumber: "",
    dob: "",
    stateId: null,
    districtId: null,
    address: "",
    isActive: false,
    isAdmin: false,
    isFillingHead: false,
    userName: "",
    password: "",
    file: null,
  });
  const [viewModel, setViewModel] = useState({});
  const {
    BOOLEAN_VALIDATOR,
    ALPHA_NUMERIC_VALIDATOR,
    DATE_VALIDATOR,
    DECIMAL_VALIDATOR,
    // EMAIL_VALIDATOR,
    GUID_VALIDATOR,
    // PHONE_NUMBER_VALIDATOR,
    POSITIVE_INTEGER,
    PASSWORD_VALIDATOR,
    STRING_VALIDATOR,
  } = SCHEMA_VALIDATOR;

  const validationSchema = yup.object({
    name: STRING_VALIDATOR,
    // emailId: EMAIL_VALIDATOR,
    // phoneNumber: PHONE_NUMBER_VALIDATOR,
    dob: DATE_VALIDATOR,
    stateId: GUID_VALIDATOR,
    districtId: GUID_VALIDATOR,
    // address: ALPHA_NUMERIC_VALIDATOR,
    isActive: BOOLEAN_VALIDATOR,
    isAdmin: BOOLEAN_VALIDATOR,
    isFillingHead: BOOLEAN_VALIDATOR,
    userName: yup.string().when(["isAdmin", "isFillingHead"], {
      is: (isAdmin, isFillingHead) => isAdmin || isFillingHead,
      then: () => STRING_VALIDATOR,
      otherwise: () => yup.string(),
    }),
    password: yup.string().when(["isAdmin", "isFillingHead"], {
      is: (isAdmin, isFillingHead) => isAdmin || isFillingHead,
      then: () => PASSWORD_VALIDATOR,
      otherwise: () => yup.string(),
    }),
    file: yup.mixed().nullable().notRequired(),
  });

  const [payload, setPayload] = useState({
    filter: [],
    pageSize: 0,
    pageIndex: 0,
  });

  const getSingleUser = async () => {
    const singleUser = (await getById("User", params.state.id)).data;

    setInitialState({
      dob: convertDateFormat(singleUser.dob, "yyyy-mm-dd"),
      emailId: singleUser.emailId,
      phoneNumber: singleUser.phoneNumber,
      address: singleUser.address,
      name: singleUser.name,
      stateId: singleUser.stateId,
      districtId: singleUser.districtId,
      isActive: singleUser.isActive,
      isAdmin: singleUser.isAdmin,
      isFillingHead: singleUser.isFillingHead,
      password: singleUser.password || "",
      userName: singleUser.username || "",
    });
  };

  const getAllPromises = () => {
    // const district = getAll("District", payload);
    const state = getAll("State", payload);

    Promise.all([state])
      .then((res) => {
        const [stat] = res;
        // const districts = dist.data.data.map((dist) => ({
        //   text: dist.name,
        //   value: dist.districtId,
        // }));
        const states = stat.data.data.map((sta) => ({
          text: sta.name,
          value: sta.stateId,
        }));
        // setDistrict(districts);
        setState(states);
      })
      .catch((err) => {});
  };

  const getDistricts = (stateId) => {
    api
      .get(`District/getByState/${stateId}`)
      .then((res) => {
        const Districts = res.data.data.map((dist) => ({
          text: dist.name,
          value: dist.districtId,
        }));
        setDistrict(Districts);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  useEffect(() => {
    getDistricts(initialState.stateId);
  }, [initialState.stateId != null]);

  useEffect(() => {
    document.title = `${isEdit ? "Edit User" : "Add User"} | Digital Filing`;
    getAllPromises();
    if (isEdit) getSingleUser();
  }, [payload]);

  return (
    <Container maxW="container.xll" padding={"20px "}>
      <Box>
        <BreadcrumbBox
          route={
            isEdit
              ? GET_BREADCRUMB_ROUTES(MENU_NAMES.EDIT_USER)
              : GET_BREADCRUMB_ROUTES(MENU_NAMES.ADD_USER)
          }
        />
      </Box>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize={true}
        initialValues={initialState}
        onSubmit={async (values) => {
          const finalPayload = {
            BranchId:
              values.isAdmin || values.isFillingHead
                ? null
                : selector.branch.userBranchId,
            Name: values.name,
            EmailId: values.emailId,
            PhoneNumber: values.phoneNumber,
            Dob: values.dob,
            StateId: values.stateId,
            DistrictId: values.districtId,
            Address: values.address,
            IsActive: values.isActive,
            IsAdmin: values.isAdmin,
            IsFillingHead: values.isFillingHead,
            Username: values.userName,
            Password: values.password,
            File: values.file,
          };
          const record =
            values.isAdmin || values.isFillingHead
              ? await api.get(`/User/username-exist/${values.userName}/null`)
              : null;
          if (record != null && record.data.data) {
            WarningAlert("Username already exist");
            return;
          }

          if (isEdit) {
            finalPayload.UserId = params.state.id;
            if (finalPayload.File === undefined) finalPayload.File = null;
            let replaceResponse = await api.put("User/replace", finalPayload, {
              headers: { "Content-Type": "multipart/form-data" },
            });
            if (replaceResponse.status === ERROR_CODES.ACCEPTED) {
              SuccessAlert("User Updated Successfully");
              navigate(ROUTE_LINKS[MENU_NAMES.GENERAL_USER]);
            }
            return;
          }
          let postResponse = await api.post("User/create", finalPayload, {
            headers: { "Content-Type": "multipart/form-data" },
          });

          if (postResponse.status === ERROR_CODES.CREATED) {
            SuccessAlert("User Created Successfully");
            navigate(ROUTE_LINKS[MENU_NAMES.GENERAL_USER]);
          }
        }}
      >
        {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
          <Form
            noValidate={true}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Box display={"flex"} justifyContent="space-between">
              <Box>
                <Heading variant={"h1"} marginBottom={"20px"}>
                  {isEdit ? "Edit User" : "Create New User"}
                </Heading>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                gap={"10px"}
                marginBottom={"20px"}
              >
                <Button
                  variant={"warning"}
                  onClick={() => {
                    navigate("/general/user");
                  }}
                >
                  Discard
                </Button>
                <Button variant={"primary"} type="submit">
                  {isLoading ? (
                    <CircularProgress
                      isIndeterminate
                      size="24px"
                      color={colors.white}
                    />
                  ) : isEdit ? (
                    "Save"
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Box>
            </Box>
            <Box
              display={"flex"}
              columnGapgap={"20px"}
              borderRadius={"10px"}
              flexDirection={"column"}
            >
              <Box
                padding={"20px"}
                borderRadius={"10px"}
                border={"1px solid #e6e6e6"}
              >
                <Box spacingX="30px" spacingY="0px" display={"flex"} w={"100%"}>
                  <Box maxW={"210px"} width={"100%"}>
                    <Box position={"relative"} className="uploadImagesBox">
                      <TextInput
                        type="file"
                        white={"80px"}
                        isRequired={false}
                        name="file"
                        value={values.file}
                        label={{
                          text: "",
                        }}
                        accept={".png,.svg,.jpg"}
                        onChange={(event) => {
                          let file = null;
                          if (event.target.files && event.target.files[0]) {
                            let fileSize = event.target.files[0];
                            if (fileSize.size > 1024 * 1024 * 2)
                              WarningAlert(
                                "Please upload a file smaller than 2 MB"
                              );
                            else {
                              file = URL.createObjectURL(fileSize);
                              setImage(URL.createObjectURL(fileSize));
                            }
                          }
                        }}
                        placeholder={""}
                        isReadOnly={isLoading}
                        position={"absolute"}
                      />
                      <CloseButton
                        className="closeUploadedImage"
                        onClick={() => setImage(null)}
                      />
                      <Image
                        className="uploadedImage"
                        src={
                          image != null
                            ? image
                            : "https://www.pngitem.com/pimgs/m/581-5813504_avatar-dummy-png-transparent-png.png"
                        }
                      />
                    </Box>
                  </Box>
                  <SimpleGrid
                    columns={{ base: "1", sm: "1", md: "2", lg: "4" }}
                    width={"100%"}
                    columnGap={"20px"}
                    borderRadius={"10px"}
                  >
                    <TextInput
                      type="text"
                      isRequired={true}
                      name="name"
                      value={values.name}
                      label={{
                        text: "Name",
                        colors: errors.name ? "red !important" : "",
                        variant: errors.name ? "errored" : "",
                      }}
                      onChange={(e) => handleChange(e)}
                      placeholder={""}
                      variant={errors.name ? "errored" : "flushed"}
                      isReadOnly={isLoading}
                    />
                    <TextInput
                      type="text"
                      name="emailId"
                      isRequired={false}
                      value={values.emailId}
                      label={{
                        text: "Email",
                        colors: errors.emailId ? "red !important" : "",
                        variant: errors.emailId ? "errored" : "",
                      }}
                      onChange={handleChange}
                      placeholder={""}
                      variant={errors.emailId ? "errored" : "flushed"}
                      isReadOnly={isLoading}
                    />
                    <TextInput
                      type="date"
                      name="dob"
                      isRequired={true}
                      placeholder={""}
                      value={values.dob}
                      onChange={handleChange}
                      label={{
                        text: "DOB",
                        colors: errors.dob ? "red !important" : "",
                        variant: errors.dob ? "errored" : "",
                      }}
                      variant={errors.dob ? "errored" : "flushed"}
                      isReadOnly={isLoading}
                    />
                    <TextInput
                      type="tel"
                      isRequired={false}
                      name="phoneNumber"
                      value={values.phoneNumber}
                      label={{
                        text: "Phone Number",
                        colors: errors.phoneNumber ? "red !important" : "",
                        variant: errors.phoneNumber ? "errored" : "",
                      }}
                      onChange={handleChange}
                      placeholder={""}
                      variant={errors.phoneNumber ? "errored" : "flushed"}
                      isReadOnly={isLoading}
                    />
                    <SelectInput
                      formControl={{
                        id: "stateId",
                        variant: "floating",
                      }}
                      name="stateId"
                      isRequired={true}
                      isReadOnly={isLoading}
                      value={values.stateId}
                      label={{
                        text: "State",
                        colors: errors.stateId ? "red !important" : "",
                        variant: errors.stateId ? "errored" : "",
                      }}
                      onChange={(e) => {
                        handleChange(e);
                        getDistricts(e.target.value);
                      }}
                      options={state}
                      variant={errors.stateId ? "errored" : "flushed"}
                    />
                    <SelectInput
                      formControl={{
                        id: "districtId",
                        variant: "floating",
                      }}
                      name={"districtId"}
                      isRequired={true}
                      isReadOnly={isLoading}
                      value={values.districtId}
                      label={{
                        text: "District",
                        colors: errors.districtId ? "red !important" : "",
                        variant: errors.districtId ? "errored" : "",
                      }}
                      onChange={handleChange}
                      variant={errors.districtId ? "errored" : "flushed"}
                      options={district}
                    />

                    <TextInput
                      type="text"
                      name="address"
                      placeholder={""}
                      isRequired={false}
                      isReadOnly={isLoading}
                      value={values.address}
                      variant={errors.address ? "errored" : "flushed"}
                      label={{
                        text: "Address",
                        colors: errors.address ? "red !important" : "",
                        variant: errors.address ? "errored" : "",
                      }}
                      onChange={handleChange}
                    />

                    <SwitchInput
                      placeholder={""}
                      isRequired={false}
                      isReadOnly={isLoading}
                      value={values.isActive}
                      name="isActive"
                      variant={errors.isActive ? "errored" : "flushed"}
                      label={{
                        text: "Active",
                        colors: errors.isActive ? "red !important" : "",
                        variant: errors.isActive ? "errored" : "",
                        fontSize: "12px",
                      }}
                      formControl={{ id: "isActive" }}
                      onChange={handleChange}
                    />

                    <SwitchInput
                      formControl={{ id: "isAdmin" }}
                      placeholder={""}
                      isRequired={false}
                      isReadOnly={isLoading}
                      isDisabled={values.isFillingHead}
                      value={values.isAdmin}
                      name="isAdmin"
                      variant={errors.isAdmin ? "errored" : "flushed"}
                      label={{
                        text: "Admin",
                        colors: errors.isAdmin ? "red !important" : "",
                        variant: errors.isAdmin ? "errored" : "",
                        fontSize: "12px",
                      }}
                      onChange={handleChange}
                    />

                    <SwitchInput
                      formControl={{ id: "isFillingHead" }}
                      placeholder={""}
                      isRequired={false}
                      isReadOnly={isLoading}
                      isDisabled={values.isAdmin}
                      value={values.isFillingHead}
                      name="isFillingHead"
                      variant={errors.isFillingHead ? "errored" : "flushed"}
                      label={{
                        text: "Filling Head",
                        colors: errors.isFillingHead ? "red !important" : "",
                        variant: errors.isFillingHead ? "errored" : "",
                        fontSize: "12px",
                      }}
                      onChange={handleChange}
                    />

                    {(values.isAdmin || values.isFillingHead) && (
                      <>
                        <TextInput
                          type="text"
                          name="userName"
                          placeholder={""}
                          isRequired={true}
                          value={values.userName}
                          variant={errors.userName ? "errored" : "flushed"}
                          label={{
                            text: "Username",
                            colors: errors.userName ? "red !important" : "",
                            variant: errors.userName ? "errored" : "",
                          }}
                          onChange={handleChange}
                        />

                        <TextInput
                          type="password"
                          name="password"
                          placeholder={""}
                          isRequired={true}
                          value={values.password}
                          variant={errors.password ? "errored" : "flushed"}
                          label={{
                            text: "Password",
                            colors: errors.password ? "red !important" : "",
                            variant: errors.password ? "errored" : "",
                          }}
                          onChange={handleChange}
                        />
                      </>
                    )}
                  </SimpleGrid>
                </Box>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default AddUser;
